<template>
  <div class="manage-clients-page">
    <PageHeader page-title="Manage Clients"></PageHeader>

    <b-card no-body>
      <template #header>
        <div class="card-header-content-between">
          <h4>Clients</h4>
          <b-form-group>
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </template>

      <b-card-body>
        <b-button variant="primary" @click="openNewClientModel=true" size="sm" v-if="isStaff">Add New</b-button>
      </b-card-body>

      <b-modal v-model="openNewClientModel" size="xl" title="New Client Wizard"
               no-close-on-esc
               no-close-on-backdrop>
        <b-form>
          <b-form-row>
            <b-col>
              <b-form-group
                id="first-name-input"
                label="First Name"
                label-for="first_name"
                description=""
              >
                <b-form-input
                  id="first_name"
                  type="text"
                  placeholder=""
                  v-model="$v.newClient.first_name.$model"
                  :class="{'is-invalid': $v.newClient.first_name.$error}"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                id="last-name-input"
                label="Last Name:"
                label-for="last_name"
              >
                <b-form-input
                  id="last_name"
                  type="text"
                  placeholder=""
                  v-model="$v.newClient.last_name.$model"
                  :class="{'is-invalid': $v.newClient.last_name.$error}"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group
                id="email-input"
                label="Email"
                label-for="email"
                description=""
              >
                <b-form-input
                  id="email"
                  type="email"
                  placeholder=""
                  v-model="$v.newClient.email.$model"
                  :class="{'is-invalid': $v.newClient.email.$error}"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                id="contact-number-input"
                label="Contact Number:"
                label-for="contact_number"
              >
                <b-form-input
                  id="contact_number"
                  type="text"
                  placeholder=""
                  v-model="$v.newClient.contact_number.$model"
                  :class="{'is-invalid': $v.newClient.contact_number.$error}"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

        </b-form>
        <template #modal-footer>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="openNewClientModel=false"
          >
            Close
          </b-button>

          <b-button size="sm" variant="success" @click="createNewClient(newClient)">
            Save
          </b-button>
        </template>
      </b-modal>

      <b-table
          id="client-list"
          :per-page="perPage"
          :current-page="currentPage"

              striped hover :fields="fields"
               :filter="filter" :items="items" :busy="isLoading"
               sort-by.sync="first_name"
               sort-desc.sync="true"
               responsive="sm"
               small
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>

        <template #cell(action)="row" v-if="isStaff">
          <b-button :to="{name: 'singleClientPage', params: {'client_id': row.item.id}}" size="sm" variant="primary" class="mr-2">
            View
          </b-button>
          <b-button @click.prevent="deleteUser(row.item.id)" variant="danger" size="sm" v-if="isSuperAdmin">Delete</b-button>
        </template>

        <template #cell(status)="row">
          <StatusBadge :status="row.value"></StatusBadge>
        </template>

        <template #cell(created_at)="row">
          {{ row.value | moment('YYYY-MM-D H:m') }}
        </template>

        <template #cell(updated_at)="row">
          {{ row.value | moment('YYYY-MM-D H:m') }}
        </template>

      </b-table>

      <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="client-list"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import StatusBadge from "@/components/StatusBadge";
import MojaAPIService from "@/service/MojaAPIService";
import {email, minLength, required} from "vuelidate/lib/validators";
import {isStaff, isSuperAdmin} from "@/helpers/api.helper";

export default {
  name: "ManageClientPage",
  components: {StatusBadge, PageHeader},
  computed: {
    isStaff() {
      return isStaff()
    },
    isSuperAdmin() {
      return isSuperAdmin()
    },
    rows() {
      return this.items.length;
    }
  },
  data() {
    return {
      isLoading: false,
      openNewClientModel: false,
      newClient: {
        'first_name': '',
        'last_name': '',
        'email': '',
        'contact_number': '',
      },
      filter: null,
      perPage: 20,
      currentPage: 1,
      fields: [
        { key: 'id', sortable: true },
        { key: 'first_name', sortable: true },
        { key: 'last_name', sortable: true },
        { key: 'email', sortable: true },
        { key: 'status', sortable: true },
        { key: 'created_at', sortable: true },
        { key: 'updated_at', sortable: true },
        { label: 'Action', key: 'action', sortable: false }
      ],
      items: []
    }
  },
  mounted() {
    this.getUsers();

  },
  validations: {
    newClient: {
      first_name: {
        required,
        minLength: minLength(3)
      },
      last_name: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email,
        minLength: minLength(5)
      },
      contact_number: {
        required,
        minLength: minLength(10)
      }
    }
  },
  methods: {
    createNewClient(newClient) {
      if (!newClient) {
        return;
      }

      newClient.status_id = 1;

      MojaAPIService.createNewClient(newClient).then(response => {
        if (response.status === 200) {
          this.getUsers();
          this.openNewClientModel = false;
        } else if (response.status >= 400) {
          this.$bvToast.toast(response.data.message, {
            title: 'Oops and error has occurred',
            variant: 'danger',
            solid: true
          })
        }
      }).catch(({response}) => {
        this.$bvToast.toast(response.data.message, {
          title: 'Oops and error has occurred',
          variant: 'danger',
          solid: true
        })
      });
    },
    deleteUser(userId) {
      if (_.isUndefined(userId)) {
        console.log('No user id given.');
        return;
      }
      this.$bvModal.msgBoxConfirm('Are you sure you delete this record?', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        cancelTitle: 'Cancel',
        cancelVariant: 'secondary',
        okVariant: 'primary',
        okTitle: 'Okay',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then((result) => {
          if (result === true) {
            console.log(userId);
            console.log(result);
            console.log('dispatch delete function.');
            return;
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err)
        });
    },
    getUsers() {
      this.isLoading = true;
      MojaAPIService.getAllClients().then(response => {
        if (response.status === 200) {
          this.items = response.data;
        }
        this.isLoading = false;
      }).catch( error => {
        console.error(error.code);
        console.error(error.message);
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
