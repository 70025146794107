<template>
  <div class="client-product-information">
    <b-card class="card" no-body>
      <template #header>
        <div class="card-header-content-between">
          <h4>Client Product Information</h4>

          <div v-if="clientProduct">
            <StatusBadge :status="clientProduct.status"></StatusBadge>
          </div>
        </div>
      </template>

      <b-overlay :show="isLoading">
        <div class="card-body">
          <form v-if="clientProduct">

            <b-form-row>
              <b-col>
                <b-form-group label="Client:" label-for="client_name">
                  <b-form-input
                    id="client_name"
                    :value="clientProduct.client.first_name + ' '  + clientProduct.client.last_name"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <b-form-group label="Client Product ID:" label-for="client_product_id">
                  <b-form-input
                    id="client_product_id"
                    type="text"
                    placeholder=""
                    required
                    v-model="clientProduct.id"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <b-form-group label="Vendor" label-for="vendor">
                  <b-form-input
                    id="vendor"
                    type="text"
                    placeholder=""
                    required
                    v-model="clientProduct.product.vendor.name"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col
                @dblclick="enableUpDataClientSolutionId()">
                <b-form-group label="Vendor UID:" label-for="vendor_uid">
                  <b-form-input
                    id="vendor_uid"
                    type="text"
                    placeholder=""
                    required
                    v-model="clientProduct.vendor_uid"
                    :disabled="disableUpDataClientSolutionId"
                  ></b-form-input>
                  <small><a href="" class="text-danger small" v-if="!disableUpDataClientSolutionId" @click.prevent="disableUpDataClientSolutionId=true">Never mind</a></small>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <b-form-group label="Cost:" label-for="cost">
                  <b-form-input
                    id="cost"
                    type="text"
                    placeholder=""
                    required
                    v-model="clientProduct.product.cost"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <b-form-group label="Type:" label-for="type">
                  <b-form-input
                    id="type"
                    type="text"
                    placeholder=""
                    required
                    v-model="clientProduct.product.name"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <b-form-group label="Description:" label-for="description">
                  <b-form-textarea
                    id="description"
                    type="text"
                    placeholder=""
                    required
                    v-model="clientProduct.product.description"
                    disabled
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <b-button variant="secondary" class="mr-2" :to="{name: 'singleClientPage', params: {'client_id': clientProduct.client.id}}">Back</b-button>
                <b-button variant="primary" class="mr-2" @click="getClientProductLogs(clientProduct.id)">Audit Logs</b-button>
                <span v-if="clientProduct">
                    <b-button variant="success" class="mr-2" v-if="disableUpDataClientSolutionId===false" @click="updateProduct()">Update</b-button>
<!--                    <b-button variant="primary" class="mr-2" v-if="clientProduct.status.id === 2" @click="confirmationPopup('ACTIVATE_PRODUCT')">Activate</b-button>-->
                    <b-button variant="warning" class="mr-2" v-if="clientProduct.status.id === 1 && isAdmin" @click="confirmationPopup('SUSPEND_PRODUCT')">Suspend</b-button>
                    <b-button variant="success" class="mr-2" v-if="clientProduct.status.id === 5 && isAdmin" @click="confirmationPopup('UNSUSPEND_PRODUCT')">Unsuspend</b-button>
                  </span>
              </b-col>
            </b-form-row>
          </form>
        </div>
      </b-overlay>
    </b-card>


    <b-modal title="Confirmation" v-model="openConfirmationModal"

             no-close-on-esc
             no-close-on-backdrop>
      <form>
        <b-form-row>
          <b-col>
            <b-form-group label="Reason:" label-for="reason">

              <b-form-textarea
                id="reason"
                type="text"
                placeholder=""
                required
                v-model="reason"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>
      </form>

      <template #modal-footer>

        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="openConfirmationModal=false"
        >
          Close
        </b-button>

        <b-button size="sm" variant="danger" @click="doActionOnProduct(clientProduct.id)">
          Save
        </b-button>
      </template>
    </b-modal>


    <b-modal title="Confirmation" v-model="openUnSuspensionModel"

             no-close-on-esc
             no-close-on-backdrop>
      <form>
        <b-form-row>
          <b-col>
            <b-form-group label="Reason:" label-for="reason">
              <b-form-textarea
                id="reason"
                type="text"
                placeholder=""
                required
                v-model="reason"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>
      </form>

      <template #modal-footer>

        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="openUnSuspensionModel=false"
        >
          Close
        </b-button>

        <b-button size="sm" variant="danger" @click="unSuspendClientProduct(clientProduct.id)">
          Save
        </b-button>
      </template>
    </b-modal>


    <b-modal
             ref="modal-audit-logs" size="xl" ok-only hover id="modal-audit-logs" title="Client Product Audit">
      <b-table striped hover sticky-header  :items="logs">
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>

        <template #cell(by)="row">
          <div style="width: 190px;">{{row.value}}</div>
        </template>

        <template #cell(note)="row">
          <div class="note" style="word-wrap: break-word; width: 80vh" v-html="row.value"></div>
        </template>

        <template #cell(created_at)="row">
          <div style="width: 190px;">
            <StandardDateDisplay :time="row.value"></StandardDateDisplay>
          </div>
        </template>

      </b-table>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import {isAdmin} from "@/helpers/api.helper";
import StatusBadge from "@/components/StatusBadge";
import MojaAPIService from "@/service/MojaAPIService";
import StandardDateDisplay from "@/components/StandardDateDisplay";

export default {
  name: "ClientProductInformation",
  components: {StandardDateDisplay, StatusBadge},
  computed: {
    isAdmin() {
      return isAdmin()
    }
  },
  data() {
    return {
      isLoading: true,
      clientProduct: null,
      openConfirmationModal: false,
      action: null,
      openUnSuspensionModel: false,
      reason: '',
      disableUpDataClientSolutionId: true,
      logs: []
    }
  },
  mounted() {
    this.getClientProduct(this.$route.params.client_product_id);
  },
  methods: {
    unSuspendClientProduct(clientProductId) {
      if (!clientProductId) {
        return;
      }
      axios.post(process.env.VUE_APP_MOJA_API_URL + '/client-product/' + clientProductId + '/unsuspend', {reason: this.reason},{
        "headers": {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.token
        }
      }).then( response => {
        // console.log(response)
        if (response.status === 200) {
          // this.clientProduct = response.data;
          this.getClientProduct(clientProductId);
          this.openUnSuspensionModel = false;
          this.isLoading = false;
        }
      });
    },
    doActionOnProduct(clientProductId) {
      if (!clientProductId) {
        return;
      }

      let url = '';
      switch (this.action) {
        case 'ACTIVATE_PRODUCT':
          url = process.env.VUE_APP_MOJA_API_URL + '/client-product/' + clientProductId + '/activate'
          break;
        case 'SUSPEND_PRODUCT':
            url = process.env.VUE_APP_MOJA_API_URL + '/client-product/' + clientProductId + '/suspend'
          break;
        case 'UNSUSPEND_PRODUCT':
            url = process.env.VUE_APP_MOJA_API_URL + '/client-product/' + clientProductId + '/unsuspend'
      }

      axios.post(url, {reason: this.reason},{
        "headers": {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.token
        }
      }).then( response => {
        if (response.status === 200) {
          this.getClientProduct(clientProductId);
          this.openConfirmationModal = false;
          this.isLoading = false;
        }  else if (response.status >= 400) {
          this.$bvToast.toast(response.data.message, {
            title: 'Oops and error has occurred',
            variant: 'danger',
            solid: true
          })
        }
      }).catch( ({response}) => {
        this.$bvToast.toast(response.data.message, {
          title: 'Oops and error has occurred',
          variant: 'danger',
          solid: true
        })
      });

      this.getClientProduct(clientProductId)
    },
    getClientProduct(clientProductId) {
      if (!clientProductId) {
        return;
      }
      MojaAPIService.getClientProductById(clientProductId).then( response => {
        // console.log(response.data)
        if (response.status === 200) {
          this.clientProduct = response.data;
          this.isLoading = false;
        }
      });
    },
    confirmationPopup(action) {
      if (!action) {
        return;
      }


      this.action = action;
      this.openConfirmationModal = true;
    },
    updateProduct() {
      // console.log(this.clientProduct.client_solution_id);
      MojaAPIService.updateClientProduct(this.clientProduct.id, this.clientProduct).then((result) => {
        if (result.status === 200) {
          this.getClientProduct(this.$route.params.client_product_id);
          this.disableUpDataClientSolutionId = true;
          this.$bvModal.msgBoxOk('Client product has been updated. Please give a few minutes for the system to sync status with 3rd party', {
            title: 'Confirmation',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: 'Okay',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          });
        }
      }).catch(({response}) => {
        // console.log(response);
        if (response.status >= 400 && response.status <= 500) {
          this.$bvToast.toast(response.data.message, {
            title: 'Oops and error has occurred',
            variant: 'danger',
            solid: true
          });
        }
      });
    },
    getClientProductLogs(clientProductId) {
      MojaAPIService.getClientProductLogs(clientProductId).then((results) => {
        let logs = [];
        const notes = results.data.notes;
        notes.forEach((data) => {
          logs.push({
            created_at: data.created_at,
            by: data.user.first_name + ' ' + data.user.last_name,
            note: data.notes,
          });
        });

        this.logs = logs;

        this.$bvModal.show('modal-audit-logs');
      });
    },
    enableUpDataClientSolutionId() {
      this.disableUpDataClientSolutionId = false;
    }
  }
}
</script>

<style scoped>
  .note, .note p{

    padding: 0;
    margin: 0;
  }

  p.note ul {
    list-style-type: none;
  }

  p.note li {
    padding: 0;
    margin: 0;
  }

  .b-table-sticky-header {
    max-height: 65vh!important;
  }
</style>
