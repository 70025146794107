import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "@/views/DashboardPage";
import ManageClientPage from "@/views/client/ManageClientPage";
import SingleClientPage from "@/views/client/SingleClientPage";
import SingleClientProductPage from "@/views/client/SingleClientProductPage";
import LoginPage from "@/views/LoginPage";
// import _ from "lodash";
// import store from "@/store"
// import MojaAPIService from "@/service/MojaAPIService";
import ProductListPage from "@/views/products/ProductListPage";
import ManageUserPage from "@/views/User/ManageUserPage";
import ClientProductsReport from "@/views/Report/ClientProductsReport";
import mojaAPIService from "@/service/MojaAPIService";
import ClientProductVouchers from "@/views/ClientProductVouchers.vue";
import ExpiredClientProductVouchersPage from "@/views/ExpiredClientProductVouchersPage.vue";
// import axios from "axios";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clients',
    name: 'clients',
    component: ManageClientPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clients/:client_id',
    name: 'singleClientPage',
    component: SingleClientPage,
    props: true, // this means you can pass the params as a property
    // beforeRouteEnter(to, from, next) {
    //   console.log('response');
    //   console.log('to.params.client_id', to.params.client_id);
    //   next();
      // MojaAPIService.findClientById(to.params.client_id).then(response => {
      //   console.log('response', response);
      //   if (response.status === 200) {
      //     console.error('super mega failure')
      //     next();
      //   } else {
      //     console.error('super mega failure')
      //   }
      // }).catch(result => {
      //   console.log('result', result)
      // });

      // console.error('api didnt run maybe')
    // },
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/client-product/:client_product_id',
    name: 'singleClientProductIdPage',
    component: SingleClientProductPage,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/client-product/report/all-clients/:report_type',
    name: 'clientProductsReport',
    component: ClientProductsReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'manageUserPage',
    component: ManageUserPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'loginPage',
    component: LoginPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/products',
    name: 'productList',
    component: ProductListPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/client-product-vouchers',
    name: 'ClientProductsVouchers',
    component: ClientProductVouchers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/expired-client-product-vouchers',
    name: 'ExpiredClientProductVouchersPage',
    component: ExpiredClientProductVouchersPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, from, next) => {
  let loggedIn = {};
  let user = mojaAPIService.heartBeat().then((response) => {
    return response.data;
  }).catch((err) => {
    console.log(err);
  });

  loggedIn = await user;

  if (loggedIn) {
    next();
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next('/');
  }

  if (loggedIn && window.location.pathname === "/") {
    next('/dashboard');
  }


  next();
})

// var retryCount = 0;

// Set up an axios interceptor to refresh the access token automatically when it expires
// axios.interceptors.response.use((response) => {
//     return response
//   }, error => {
//     const originalRequest = error.config
//     if (error.response.status === 401 && retryCount <= 3) {
//
//       // Make an API call to the refresh token endpoint with the refresh token
//       return store.dispatch('refreshToken', store.state.refreshToken).then(() => {
//         retryCount ++;
//         console.log('retryCount', retryCount)
//         // Update the axios request with the new access token
//         originalRequest.headers['Authorization'] = 'Bearer ' + store.state.token
//         return axios(originalRequest)
//       }).catch(() => {
//
//         store.dispatch('logOut').then(()=>{
//           console.log('We tried to re-auth you multiple time and all failed.')
//         });
//
//         const homeRoutes = [
//             'home',
//             'loginPage'
//         ]
//         if (!homeRoutes.includes(router.currentRoute.name)) {
//           console.log('testing')
//           router.push('/login')
//         }
//
//         // return Promise.reject(error);
//       });
//     } else {
//       console.log('Could not make this request at this time, please contact the development team.::' + error);
//     }
//
//
//     return Promise.reject(error)
//   })

// axios.interceptors.response.use(response => {
//   return response
// }, error => {
//
//   const originalRequest = error.config
//
//   if (error.response.status === 401 && !originalRequest._retry) {
//     console.log(originalRequest)
//     originalRequest._retry = true
//
//     // Make an API call to the refresh token endpoint with the refresh token
//     return store.dispatch('refreshToken', store.state.refreshToken).then(() => {
//       console.log('testing here ')
//       // Update the axios request with the new access token
//       originalRequest.headers['Authorization'] = 'Bearer ' + store.state.token
//       return axios(originalRequest)
//     })
//   }
//
//   return Promise.reject(error)
// });

export default router
