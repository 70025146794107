<template>
  <div class="client-product-report">
    <PageHeader page-title="Reporting"></PageHeader>

    <b-card no-body>
      <template #header>
        <div class="card-header-content-between">
          <h4>Clients</h4>
          <b-form-group>
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </template>

      <b-card-body>
<!--{{$route.params.report_type}}-->
<!--        <pre>{{items}}</pre>-->
        <b-nav card-header tabs>
          <b-nav-item :to="{name: 'clientProductsReport', params: {'report_type': 'total_client_products'}}"
                      exact exact-active-class="active">All</b-nav-item>
          <b-nav-item :to="{name: 'clientProductsReport', params: {'report_type': 'active_without_voucher_client_products'}}"
                      exact exact-active-class="active">Active Without Voucher Client Products</b-nav-item>
        </b-nav>

<!--        <b-button variant="primary mr-2" size="sm" @click="showAllClientsWithout()">All</b-button>-->
<!--        <b-button variant="warning" size="sm" @click="showClientsWithoutVouchers()">Active Without Voucher Client Products</b-button>-->
        <!--        <pre>{{ items }}</pre>-->
      </b-card-body>

      <b-table striped hover :fields="fields"
               :filter="filter"
               :items="items"
               :busy="isLoading"
               sort-by.sync="first_name"
               sort-desc.sync="true"
               responsive="sm"
               small
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>

        <template #cell(action)="row">
          <b-button :to="{name: 'singleClientPage', params: {'client_id': row.item.id}}" size="sm" variant="primary" class="mr-2">
            View
          </b-button>
          <b-button @click.prevent="deleteUser(row.item.id)" variant="danger" size="sm">Delete</b-button>
        </template>

        <template #cell(product_type)="row">
          <router-link :to="{name: 'singleClientProductIdPage', params: {'client_product_id': row.value.id}}">
            {{row.value.name}}
          </router-link>
        </template>

        <template #cell(status)="row">
          <StatusBadge :status="row.value"></StatusBadge>
        </template>

        <template #cell(client)="row">
          <router-link :to="{name: 'singleClientPage', params: {'client_id': row.value.id }}" size="sm">{{ row.value.first_name }} {{ row.value.last_name }}</router-link>
        </template>

        <template #cell(created_at)="row">
          <StandardDateDisplay :time="row.value"></StandardDateDisplay>
        </template>

        <template #cell(updated_at)="row">
          <StandardDateDisplay :time="row.value"></StandardDateDisplay>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import StatusBadge from "@/components/StatusBadge";
import MojaAPIService from "@/service/MojaAPIService";
import StandardDateDisplay from "@/components/StandardDateDisplay";
// import _ from "lodash"
export default {
  name: "ClientProductsReport",
  components: {StandardDateDisplay, StatusBadge, PageHeader},
  data() {
    return {
      isLoading: false,
      filter: null,
      filter_by: '',
      fields: [
        { key: 'id', sortable: true },
        { key: 'product_type', sortable: true },
        { key: 'client', sortable: true },
        { key: 'client_solution_id', sortable: true },
        { key: 'status', sortable: true },
        { key: 'created_at', sortable: true },
        { key: 'updated_at', sortable: true }
      ],
      items: []
    }
  },
  mounted() {
    this.filter_by = this.$route.params.report_type;
    console.log(this.$route.params.report_type);
    this.isLoading = true;
    MojaAPIService.getClientProductDetailedReport().then((response) => {
      this.items = response.data.break_down[this.filter_by];
      this.isLoading = false;
    });


  },
  methods: {
    showAllClientsWithout() {
      this.isLoading = true;
      MojaAPIService.getClientProductDetailedReport().then((response) => {
        console.log(response.data);
        this.items = response.data.break_down.total_client_products
        this.isLoading = false;
      });
    },
    showClientsWithoutVouchers() {
      this.isLoading = true;
      MojaAPIService.getClientProductDetailedReport().then((response) => {
        console.log(response.data);
        this.items = response.data.break_down.active_without_voucher_client_products
        this.isLoading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
