import axios from 'axios'
// import store from "@/store";

const mojaApi = axios.create({
    baseURL: process.env.VUE_APP_MOJA_API_URL
});

mojaApi.interceptors.request.use(
    config => {
        // Do something before sending the request
        return config;
    },
    error => {
        // Do something with the request error
        return Promise.reject(error);
    }
);

// mojaApi.interceptors.response.use(
//     (response) => {
//         console.warn(response);
//         // Do something with the response data
//         return response;
//     },
//     (error) => {
//
//         console.warn(error);
//         // Do something with the response error
//         if (error.response.status === 401) {
//             store.dispatch('logOut').then(() => {
//                 console.log('Redirecting....');
//             });
//
//             // window.location = '/';
//
//             return;
//         }
//         return Promise.reject(error);
//     }
// );

export default mojaApi;