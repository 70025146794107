<template>
  <div class="standard-date-display">
    {{ time | moment('YYYY-MM-D  hh:mm a') }}
  </div>
</template>

<script>
export default {
  name: "StandardDateDisplay",
  props: {
    time: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
