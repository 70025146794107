import { render, staticRenderFns } from "./ClientProductAttributes.vue?vue&type=template&id=531fd161&scoped=true&"
import script from "./ClientProductAttributes.vue?vue&type=script&lang=js&"
export * from "./ClientProductAttributes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531fd161",
  null
  
)

export default component.exports