import { render, staticRenderFns } from "./ExpiredClientProductVouchersPage.vue?vue&type=template&id=19eef728&scoped=true&"
import script from "./ExpiredClientProductVouchersPage.vue?vue&type=script&lang=js&"
export * from "./ExpiredClientProductVouchersPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19eef728",
  null
  
)

export default component.exports