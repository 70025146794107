<template>
  <div class="client-product-topups">
    <div class="client-product-information">
      <b-card class="card" no-body>
        <template #header>
          <div class="card-header-content-between">
            <h4>Top Ups</h4>
            <b-form-group>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to search"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </template>

        <b-overlay :show="isLoading" v-if="clientProductTopUps">
          <div class="card-body">
            <b-button variant="primary" @click="openTopUpWindow()" v-if="isStaff">Top up</b-button>
          </div>

          <b-modal v-model="isTopUpModelOpen" title="Product Top Up"

                   no-close-on-esc
                   no-close-on-backdrop>
            <form>

              <b-form-row>
                <b-col>
                  <b-form-group label="Voucher number/Reference number:" label-for="voucher_number">
                    <b-form-input
                      id="voucher_number"
                      type="text"
                      placeholder=""
                      required
                      :class="{'is-invalid': $v.topUpData.voucher_number.$error}"
                      v-model.trim="$v.topUpData.voucher_number.$model"
                    ></b-form-input>

                    <b-form-invalid-feedback>
                      <div v-if="!$v.topUpData.voucher_number.required">Voucher number/reference number is required.</div>
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    label="Days:"
                    label-for="days"
                  >
                    <b-form-select
                      id="days"
                      value-field="id"
                      text-field="name"
                      :options="dayOptions"
                      :class="{'is-invalid': $v.topUpData.top_up_product_id.$error}"
                      v-model.trim="$v.topUpData.top_up_product_id.$model"
                    >
                      <option value="">-- Select amount of days --</option>
                    </b-form-select>

                    <b-form-invalid-feedback>
                      <div v-if="!$v.topUpData.top_up_product_id.required">Voucher validity days number is required.</div>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>

            </form>

            <template #modal-footer>

              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="isTopUpModelOpen=false"
              >
                Close
              </b-button>

              <b-button size="sm" variant="danger" @click="topUpAccount(clientProductId)" :disabled="$v.topUpData.$invalid">
                Save
              </b-button>
            </template>
          </b-modal>

          <b-table striped hover
                   :fields="fields"
                   :filter="filter"
                   :items="clientProductTopUps"
                   :per-page="perPage"
                   :current-page="currentPage"
                   :busy="isLoading"
                   sort-by="id"
                   sort-desc
                   responsive="sm"
                   small
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>

<!--            <template #cell(action)="row">-->
<!--              <router-link :to="{name: 'singleClientProductIdPage', params: {'client_product_id': row.item.id}}" class="btn btn-primary mr-2">Manage</router-link>-->
<!--            </template>-->
<!--            -->
            <template #cell(voucher_number)="row">
              <router-link to="#">{{row.value}}</router-link>
            </template>


            <template #cell(start_date)="row">
              <StandardDateDisplay :time="row.value"></StandardDateDisplay>
            </template>
            <template #cell(end_date)="row">
              <StandardDateDisplay :time="row.value"></StandardDateDisplay>
            </template>


            <template #cell(status)="row">
              <StatusBadge :status="row.value"></StatusBadge>
            </template>


            <template #cell(action)="row">
              <b-button variant="danger" size="sm" class="mx-2"
                        v-if="row.item.status.id === 2 || row.item.status.id === 12 || row.item.status.id === 15"
                        @click="cancelPendingClientProductVoucher(row.item.id)"
              >
                <b-icon-x-circle-fill></b-icon-x-circle-fill>
              </b-button>
              <b-button variant="secondary"
                        size="sm"
                        v-if="row.item.status.id === 12"
                        @click="retryClientProductVoucher(row.item.id)"
              >
                <b-icon-arrow-repeat></b-icon-arrow-repeat>
              </b-button>
              <b-button variant="info"
                        size="sm"
                        v-if="row.item.status.id === 15"
                        @click="approveClientProductVoucher(row.item.id)"
              >
                <b-icon-check/>
              </b-button>
            </template>

          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="client-list"
          ></b-pagination>
        </b-overlay>
      </b-card>


<!--      <b-modal v-model="isTopUpModelOpen" title="Product Top Up"-->

<!--               no-close-on-esc-->
<!--               no-close-on-backdrop>-->
<!--        <b-button variant="info" size="sm">Notes</b-button>-->
<!--      </b-modal>-->
      </div>
  </div>
</template>

<script>
import axios from "axios";
import StandardDateDisplay from "@/components/StandardDateDisplay";
import StatusBadge from "@/components/StatusBadge";
import { required } from 'vuelidate/lib/validators'
import {isStaff} from "@/helpers/api.helper";
import MojaAPIService from "@/service/MojaAPIService";

export default {
  name: "ClientProductTopUps",
  components: {StatusBadge, StandardDateDisplay},
  computed: {
    isStaff() {
      return isStaff();
    },
    rows() {
      return this.clientProductTopUps.length
    }
  },
  mounted() {
    this.clientProductId = this.$route.params.client_product_id;

    this.getVouchers(this.$route.params.client_product_id);

    this.getProducts();
  },
  data() {
    return {
      isLoading: true,
      perPage: 5,
      currentPage: 1,
      clientProductId: null,
      clientProductTopUps: [],
      isTopUpModelOpen: false,
      topUpData: {
        voucher_number: '',
        top_up_product_id: ''
      },
      dayOptions: [],
      fields: [
        { label: 'ID', key: 'id', sortable: true },
        { label: 'Voucher', key: 'voucher_number', sortable: true },
        { label: 'Days', key: 'valid_days', sortable: true },
        { label: 'Cost', key: 'product.cost', sortable: true },
        { label: 'Start', key: 'start_date', sortable: true },
        { label: 'End', key: 'end_date', sortable: true },
        { label: 'Status', key: 'status', sortable: true },
        { label: 'Action', key: 'action', sortable: false },

        // { label: 'Action', key: 'action', sortable: true },
      ],
      filter: null
    }
  },
  validations: {
    topUpData: {
      voucher_number: {
        required
      },
      top_up_product_id: {
        required
      }
    }
  },
  methods: {
    openTopUpWindow() {
      this.isLoading = true;
        this.getProducts().then( response => {
          if (response.status === 200) {
            const data = response.data;

            this.dayOptions = [];
            data.forEach( (current) => {
              this.dayOptions.push({
                id: current.id,
                name: current.name + ' - R' + current.cost,
              });
            });

            this.isLoading=false;
            this.isTopUpModelOpen=true;
          }
        });
    },
    getProducts() {
      return MojaAPIService.getAllTopUpProducts(this.clientProductId);
    },
    getVouchers(clientProductId) {
      axios.get(process.env.VUE_APP_MOJA_API_URL + '/client-product/' + clientProductId +'/vouchers', {
        "headers": {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.token
        }
      }).then(response => {
        if(response.status === 200) {
          this.clientProductTopUps = response.data
          this.isLoading = false
        }
      })
    },
    topUpAccount(clientProductId) {
      this.isLoading = true;
      if (!clientProductId) {
        return;
      }

      axios.post(process.env.VUE_APP_MOJA_API_URL + '/client-product-voucher/' + clientProductId, this.topUpData,{
        "headers": {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.token
        }
      }).then(response => {
        if (response.status === 200) {
          this.getVouchers(this.clientProductId);
          this.isTopUpModelOpen = false;
          this.topUpData = {
            voucher_number: '',
            top_up_product_id: ''
          }
        }
        this.isLoading = true;
      }).catch(({response}) => {
        this.$bvToast.toast(response.data.message, {
          title: 'Oops and error has occurred',
          variant: 'danger',
          solid: true
        });
        this.isLoading = false;
      });
    },
    cancelPendingClientProductVoucher(clientProductVoucherId) {
      this.isLoading = true
      MojaAPIService.cancelClientProductVoucher(clientProductVoucherId).then(result => {
        if (result.status === 200) {
          this.getVouchers(this.$route.params.client_product_id);
          this.isLoading = false;
        }
      }).catch(({response}) => {
        this.$bvToast.toast(response.data.message, {
          title: 'Oops and error has occurred',
          variant: 'danger',
          solid: true
        });
        this.isLoading = false;
      });
    },
    retryClientProductVoucher(clientProductVoucherId) {
      this.isLoading = true
      MojaAPIService.retryClientProductVoucher(clientProductVoucherId).then(result => {
        if (result.status === 200) {
          this.getVouchers(this.$route.params.client_product_id);
          this.isLoading = false;
        }
      }).catch(({response}) => {
        this.$bvToast.toast(response.data.message, {
          title: 'Oops and error has occurred',
          variant: 'danger',
          solid: true
        });
        this.isLoading = false;
      });
    },
    approveClientProductVoucher(clientProductVoucherId) {
        if(!clientProductVoucherId) {
            return;
        }

        this.isLoading = true;

        this.$bvModal.msgBoxConfirm('Are you sure you want approve this voucher?', {
            title: 'Confirmation',
            size: 'sm',
            buttonSize: 'sm',
            cancelTitle: 'Cancel',
            cancelVariant: 'secondary',
            okVariant: 'primary',
            okTitle: 'Okay',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
        })
        .then((result) => {
            if (result === true) {
                console.log(result)
              MojaAPIService.approveClientProductVoucher(clientProductVoucherId).then(response => {
                  if (response.status === 200) {
                      this.getVouchers(this.$route.params.client_product_id);
                  }
                  console.log('response: ', response)
                  this.isLoading = false;
              }).catch(({response}) => {
                  console.log('response: ', response);
                  this.$bvToast.toast(response.data.message, {
                      title: 'Oops and error has occurred',
                      variant: 'danger',
                      solid: true
                  });
                  this.isLoading = false;
              });
            }
        }).catch(({response}) => {
            console.log('response: ', response)
            this.$bvToast.toast(response.data.message, {
                title: 'Oops and error has occurred',
                variant: 'danger',
                solid: true
            });
            this.isLoading = false;
        });
    }
  }
}
</script>

<style scoped>

</style>
