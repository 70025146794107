<template>
  <div class="single-client-page">
    <PageHeader page-title="Manage Client"></PageHeader>

   <div class="row">
     <div class="col-md-12">
       <PersonalInformation :router-client-id="clientId"></PersonalInformation>
     </div>
   </div>

   <div class="row">
     <div class="col-md-12">
       <ClientProducts :client_id="client_id"></ClientProducts>
     </div>
   </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PersonalInformation from "@/components/client/PersonalInformation";
import ClientProducts from "@/components/client/ClientProducts";
import { integer } from "vuelidate/lib/validators";
export default {
  name: "SingleClientPage",
  props: {
    client_id: {
      required: true,
      type: integer
    }
  },
  components: {ClientProducts, PersonalInformation, PageHeader},
  data() {
    return {
      client: {},
      clientId: null
    }
  },
  computed: {
  },
  mounted() {
    // if (this.client_id) {
    //   this.clientId = this.client_id;
    // } else {
    //   console.error('FUCK!');
    // }
  }
}
</script>

<style scoped>

</style>
