<template>
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col">
        <h1 class="page-header-title">{{ pageTitle }}</h1>
      </div>
      <!-- End Col -->

      <div class="col-auto">
<!--        <a class="btn btn-primary" href="javascript:;" data-bs-toggle="modal" data-bs-target="#inviteUserModal">-->
<!--          <i class="bi-person-plus-fill me-1"></i> Invite users-->
<!--        </a>-->
      </div>
      <!-- End Col -->
    </div>
    <!-- End Row -->
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    pageTitle: {
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-header {
    border-bottom: 0.0625rem solid rgba(231,234,243,.7);
    padding-bottom: 2rem;
    margin-bottom: 2.25rem;

  }
</style>
