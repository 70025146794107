import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/main.scss'

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

Vue.use(require('vue-moment'));

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Vue.config.productionTip = false

// Vue.config.errorHandler = function(err, vm, info)  {
//   // alert('[Global Error Handler]: Error in ' + info + ': ' + err);
//   console.log('[Global Error Handler]: Error in ' + info + ': ' + err)
// };
//
// Vue.config.warnHandler = function(msg, vm, trace) {
//   // alert(`Warn: ${msg}\nTrace: ${trace}`);
//   console.log(`[Global Warn Handler] : ${msg}\nTrace: ${trace}`)
// }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
