<template>
  <div id="app">
    <b-navbar class="main-nav navbar-bordered mb-5"
              toggleable="lg"
              type="dark"
              v-if="$store.state.loggedUser.id"
              :class="{'is-wide': !$store.state.isOpen}"
    >
      <b-navbar-brand href="#" @click.prevent="$store.state.isOpen =! $store.state.isOpen">
        <b-icon icon="list"></b-icon>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
<!--        <b-navbar-nav>-->
<!--          <b-nav-item href="#">Link</b-nav-item>-->
<!--          <b-nav-item href="#" disabled>Disabled</b-nav-item>-->
<!--        </b-navbar-nav>-->

        <b-nav-form>
          <div class="magic-search">
            <b-form-input type="search" size="md" class="mr-sm-2" placeholder="Magic Search" v-model="search_term" @keyup="magicSearch()"></b-form-input>
            <div class="magic-search-results" v-if="search_term">
              <ul v-if="search_results.length > 0">
                <li v-for="result in search_results" :key="result.id">
                  <router-link @click.native="clearSearchForm()" :to="{name: 'singleClientPage', params: {'client_id': result.id}}">
                    {{ result.first_name }} {{ result.last_name }} - {{ result.email}}
                  </router-link>
                </li>
              </ul>

              <div class="magic-search-status" v-if="search_is_loading">Loading...</div>
            </div>
          </div>
<!--          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>-->
        </b-nav-form>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

<!--          <b-nav-item-dropdown text="Lang" right>-->
<!--            <b-dropdown-item href="#">EN</b-dropdown-item>-->
<!--            <b-dropdown-item href="#">ES</b-dropdown-item>-->
<!--            <b-dropdown-item href="#">RU</b-dropdown-item>-->
<!--            <b-dropdown-item href="#">FA</b-dropdown-item>-->
<!--          </b-nav-item-dropdown>-->

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              {{ $store.state.loggedUser.first_name }} {{ $store.state.loggedUser.last_name }}
            </template>
<!--            <b-dropdown-item href="#">Profile</b-dropdown-item>-->
            <b-dropdown-item @click.prevent="logout()">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div class="page">

      <aside class="side-bar" v-if="$store.getters.isLoggedIn" :class="{'is-closed': !$store.state.isOpen}">
        <div class="sidebar-header">
          <b-navbar>
            <b-navbar-brand href="/"><h3>Moja Portal</h3></b-navbar-brand>
          </b-navbar>
        </div>
        <b-nav vertical class="sidebar-menu">
          <b-nav-item to="/dashboard" active>Dashboard</b-nav-item>
          <b-nav-item to="/clients" v-if="isStaff()">Manage Clients</b-nav-item>
          <b-nav-item to="/products" v-if="isAdmin()">Manage Products</b-nav-item>
          <b-nav-item to="/users" v-if="isAdmin()">Manage Users</b-nav-item>
          <b-nav-item :to="{name: 'ClientProductsVouchers'}" v-if="isAdmin()">Manage Vouchers</b-nav-item>
          <b-nav-item :to="{name: 'ExpiredClientProductVouchersPage'}" v-if="isAdmin()">Openserve Vouchers</b-nav-item>
          <b-nav-item @click.prevent="logout()">Logout</b-nav-item>
        </b-nav>
      </aside>

      <main class="main" :class="{'is-wide': !$store.state.isOpen ||  !$store.getters.isLoggedIn}">

        <div class="content container-fluid">

          <b-alert variant="warning" show dismissible v-if="system.env === 'development'">
            <small><b>Development:</b> <em>{{ system.api_url }}</em></small>
          </b-alert>
<!--          <b-button @click="stopLoginPoller">Stop login poller</b-button>-->
          <router-view :key="$route.path"/>
        </div>
        <div class="footer">
<!--          This is our footer-->
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import MojaAPIService from "@/service/MojaAPIService";
import {isAdmin, isSuperAdmin, isStaff} from "@/helpers/api.helper";
import router from "@/router";
import mojaAPIService from "@/service/MojaAPIService";
// import mojaAPIService from "@/service/MojaAPIService";
// import mojaAPIService from "@/service/MojaAPIService";
// import router from "@/router";

export default {
  name: 'App',
  components: {},
  computed: {
    isStaff(){
      return isStaff;
    },
    isAdmin(){
      return isAdmin;
    },
    isSuperAdmin(){
      return isSuperAdmin;
    },
    system() {
      return {
        env: process.env.NODE_ENV,
        api_url: process.env.VUE_APP_MOJA_API_URL
      }
      // return process.env.VUE_APP_MOJA_API_URL;
    }
  },
  data() {
    return {
      heartBeatHandler: null,
      search_term: null,
      search_is_loading: false,
      search_results: [],
      isLoggedIn: this.$store.getters.isLoggedIn
    }
  },
  mounted() {
    mojaAPIService.heartBeat().then(response => {
      if (response.status !== 200) {
        this.$store.dispatch('logOut').then(() => {
          router.push('/');
        });
      }
    })
    .catch(() => {
      this.$store.dispatch('logOut').then(() => {
        router.push('/');
      });
    })
  },
  methods: {
    logout() {
      this.$bvModal.msgBoxConfirm('Are you sure you want logout?', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        cancelTitle: 'Cancel',
        cancelVariant: 'secondary',
        okVariant: 'primary',
        okTitle: 'Okay',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
      .then((result) => {
        if (result === true) {
          // this.$router.redirect('/login');
          this.$store.dispatch('logOut').then(() => {
            router.push('/');
          });
        }
      })
    },
    magicSearch() {
      // console.log(this.search_term.length)
      if (this.search_term && this.search_term.length >= 4) {
        this.search_is_loading = true;
        MojaAPIService.magicSearch(this.search_term).then( result => {
          if (result.status === 200) {
            this.search_results = result.data
            this.search_is_loading = false
          }
        });
      }
    },
    clearSearchForm() {
      this.search_term = null
      this.search_results = []
    }
  }
}
</script>

<style lang="scss">
@import "assets/scss/main";

.magic-search {
  //background-color: #1f6683;
  position: relative;

  input {
    width: 20rem!important;
  }

  .magic-search-results {
    position: absolute;
    top: 2.2rem;
    left: 0;
    z-index: 1;
    background-color: #ffffff;
    padding: 1rem 0;
    width: 20rem;
    border:1px solid lightblue;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    .magic-search-status {
      text-align: center;
      font-size: 1rem;
    }
  }

  li {
    list-style-type: none;
    border-bottom: 1px solid lightgray;
    padding:0 1rem;
    &:hover {
      background-color: #ebebeb;
    }

    a {
      text-decoration: none;
      font-size: .8rem;
      color: #051626;
      font-weight: normal;
    }
  }
}
</style>
