<template>
  <div class="dashboard-page">
    <div class="row"   v-if="!isLoading">
      <div class="col-lg-4">
          <b-overlay :show="isLoading" rounded="sm">
          <ReportCard title="Total Client Products" :count="summary.total_client_products" route-name="total_client_products" card-type="text-primary"/>
        </b-overlay>
      </div>
      <div class="col-lg-4">
        <b-overlay :show="isLoading" rounded="sm">
          <ReportCard title="Active Client Products" :count="summary.active_client_products" route-name="active_client_products" card-type="text-success"/>
        </b-overlay>
      </div>
      <div class="col-lg-4">
        <b-overlay :show="isLoading" rounded="sm">
          <ReportCard title="Suspended Client Products" :count="summary.suspended_client_products" route-name="suspended_client_products" card-type="text-secondary"/>
        </b-overlay>
      </div>
      <div class="col-lg-4">
        <b-overlay :show="isLoading" rounded="sm">
          <ReportCard title="Pending Client Products" :count="summary.pending_client_products" route-name="pending_client_products" card-type="text-warning"/>
        </b-overlay>
      </div>
      <div class="col-lg-4">
        <b-overlay :show="isLoading" rounded="sm">
          <ReportCard title="Cancelled Client Products" :count="summary.cancelled_client_products" route-name="cancelled_client_products" card-type="text-danger"/>
        </b-overlay>
      </div>
      <div class="col-lg-4">
        <b-overlay :show="isLoading" rounded="sm">
          <ReportCard title="Without Active Vouchers" :count="summary.active_without_voucher_client_products" route-name="active_without_voucher_client_products" card-type="text-secondary"/>
        </b-overlay>
      </div>
      <div class="col-lg-4">
        <b-overlay :show="isLoadingFailedClientProductVouchers" rounded="sm">
          <ReportCard title="Failed Vouchers" :count="failedClientProductVouchers.length" route-name="active_without_voucher_client_products" card-type="text-danger"/>
        </b-overlay>
      </div>
      <div class="col-lg-4">
        <b-overlay :show="isLoadingPendingApprovalClientProductVouchers" rounded="sm">
          <ReportCard title="Pending Approval Vouchers" :count="pendingApprovalClientProductVouchers.length" route-name="active_without_voucher_client_products" card-type="text-danger"/>
        </b-overlay>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-lg-6">
        <b-card no-body>
          <template #header>
            <div class="card-header-content-between">
              <h4>Failed Vouchers</h4>
              <b-form-group>
                <b-input-group>
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </template>

          <b-card-body>
          </b-card-body>
          <b-table
              id="paystack-list"
              striped hover sticky-header
              :fields="failedClientProductVoucherFields"
              :items="failedClientProductVouchers"
              :busy="isLoadingFailedClientProductVouchers"
              sort-by.sync="id"
              sort-desc.sync="true"
              responsive="sm"
              small
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(voucher_number)="row">
              <router-link :to="{name: 'singleClientProductIdPage', params: {'client_product_id':row.item.client_product.id }}">{{ row.value }}</router-link>
            </template>

            <template #cell(client_product)="row">
              <router-link :to="{name: 'singleClientProductIdPage', params: {'client_product_id':row.item.client_product.id }}">
                {{ row.item.client_product.client.first_name }} {{ row.item.client_product.client.last_name }}
              </router-link>
            </template>

            <template #cell(created_at)="row">
              <StandardDateDisplay :time="row.value"></StandardDateDisplay>
            </template>
          </b-table>
        </b-card>
      </div>
      <div class="col-lg-6">
        <b-card no-body>
          <template #header>
            <div class="card-header-content-between">
              <h4>Pending Approval Vouchers</h4>
              <b-form-group>
                <b-input-group>
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </template>

          <b-card-body>
          </b-card-body>
          <b-table
              id="paystack-list"
              striped hover sticky-header
              :fields="failedClientProductVoucherFields"
              :items="pendingApprovalClientProductVouchers"
              :busy="isLoadingPendingApprovalClientProductVouchers"
              sort-by.sync="id"
              sort-desc.sync="true"
              responsive="sm"
              small
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(voucher_number)="row">
              <router-link :to="{name: 'singleClientProductIdPage', params: {'client_product_id':row.item.client_product.id }}">{{ row.value.substring(0, 10) }}</router-link>
            </template>

            <template #cell(client_product)="row">
              <router-link :to="{name: 'singleClientProductIdPage', params: {'client_product_id':row.item.client_product.id }}">
                {{ row.item.client_product.client.first_name }} {{ row.item.client_product.client.last_name }}
              </router-link>
            </template>

            <template #cell(created_at)="row">
              <StandardDateDisplay :time="row.value"></StandardDateDisplay>
            </template>
          </b-table>
        </b-card>
      </div>
    </div>


    <div class="row mt-3">
      <div class="col-lg-12">
        <b-card no-body>
          <template #header>
            <div class="card-header-content-between">
              <h4>Paystack Events</h4>
              <b-form-group>
                <b-input-group>
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </template>

          <b-card-body>
          </b-card-body>
          <b-table
              id="paystack-list"
              striped hover sticky-header
              :fields="fields"
              :items="paystackWebhooks"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              :busy="isLoadingPaystackWebhooks"
              sort-by.sync="id"
              sort-desc.sync="true"
              responsive="sm"
              small
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>

            <template #cell(comment)="row">
              <a href="#" @click.prevent="viewComment(row.value)">View</a>
            </template>
          </b-table>

          <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="paystack-list"
          ></b-pagination>
        </b-card>
      </div>
    </div>

  </div>
</template>
<!--private int $id;-->
<!--private ?string $reference;-->
<!--private ?string $channel;-->
<!--private ?string $brand;-->
<!--private ?string $customerName;-->
<!--private ?string $customerEmail;-->
<!--private ?string $processingStatus;-->
<!--private ?string $amount;-->
<!--private ?string $comment;-->
<!--private ?string $createdAt;-->
<script>
import ReportCard from "@/components/Reporting/ReportCard";
import MojaAPIService from "@/service/MojaAPIService";
import StandardDateDisplay from "@/components/StandardDateDisplay.vue";
export default {
  name: "DashboardPage",
  components: {StandardDateDisplay, ReportCard},
  data() {
    return {
      isLoading: false,
      isLoadingPaystackWebhooks: false,
      isLoadingFailedClientProductVouchers: false,
      isLoadingPendingApprovalClientProductVouchers: false,
      summary: {
        'total_client_products': 0,
        'active_client_products': 0,
        'suspended_client_products': 0,
        'pending_client_products': 0,
        'cancelled_client_products': 0,
        'active_without_voucher_client_products': 0,
      },
      failedClientProductVouchers: [],
      failedClientProductVoucherFields: [
        { key: 'id', sortable: true },
        { key: 'client_product', label: 'Client', sortable: false },
        { key: 'voucher_number', sortable: false },
        { key: 'valid_days', sortable: true },
        { key: 'created_at', sortable: true },
      ],
      pendingApprovalClientProductVouchers: [],
      items: [],
      paystackWebhooks: [],
      filter: null,
      perPage: 20,
      currentPage: 1,
      fields: [
          { key: 'id', sortable: true },
          { key: 'customer_name', label: 'Name', sortable: true },
          { key: 'customer_email', label: 'Email', sortable: true },
          { key: 'processing_status', label: 'Status', sortable: true },
          { key: 'reference', sortable: true },
          { key: 'brand', sortable: true },
          { key: 'amount', sortable: true },
          { key: 'created_at', label: 'Created', sortable: true },
          { key: 'comment', sortable: true },
      ],
    }
  },
    computed: {
      rows() {
          return this.paystackWebhooks.length;
      }
    },
  mounted() {
    this.getReportSummary();
    this.getPendingApprovalClientProductVoucherReport();
    this.getFailedClientProductVouchers();
    this.getPaystackWebhooks();
  },
  methods: {
    getReportSummary() {
      this.isLoading = true;
      return MojaAPIService.getClientProductSummaryReport().then((results => {
        if (results.status === 200) {
          this.summary = results.data.summary;
          this.isLoading = false;
          return results.data;
        }
      }))
      .catch(({reponse}) => {
        console.log(reponse);
        this.isLoading = false;
      });
    },
    getFailedClientProductVouchers() {
      this.isLoadingFailedClientProductVouchers = true;
      return MojaAPIService.getFailedClientProductVoucherReport().then((results => {
        if (results.status === 200) {
          this.failedClientProductVouchers = results.data.failed_client_products_vouchers;
          this.isLoadingFailedClientProductVouchers = false;
        } else {
          console.error(results);
        }
      }))
      .catch(({response}) => {
        console.log(response);
        this.isLoadingFailedClientProductVouchers = false;
      });
    },
    getPendingApprovalClientProductVoucherReport() {
      this.isLoadingPendingApprovalClientProductVouchers = true;
      return MojaAPIService.getPendingApprovalClientProductVoucherReport().then((results => {
        if (results.status === 200) {
          this.pendingApprovalClientProductVouchers = results.data.pending_approval_client_products_vouchers;
          this.isLoadingPendingApprovalClientProductVouchers = false;
        } else {
          console.error(results);
        }
      }))
      .catch(({response}) => {
        console.log(response);
        this.isLoadingFailedClientProductVouchers = false;
      });
    },
    getPaystackWebhooks() {
        this.isLoadingPaystackWebhooks = true;
        return MojaAPIService.getPaystackWebhooks().then((results => {
            if (results.status === 200) {
                this.paystackWebhooks = results.data;
                this.isLoadingPaystackWebhooks = false;
            }
        }))
        .catch(({reponse}) => {
            console.log(reponse);
            this.isLoadingPaystackWebhooks = false;
        });
    },
    viewComment(comment) {
        if (!comment) {
            comment = 'No comment';
        }
        this.$bvModal.msgBoxOk(comment, {
            title: 'View Comment',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: 'Okay',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
        });
    }
  }
}
</script>

<style scoped>

</style>
