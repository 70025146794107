// import axios from "axios";
import store from '../store'
import { getHeaders } from "@/helpers/api.helper";
import mojaApi from "@/service/BaseMojaApiService";

class MojaAPIService {

  /**
   * Logins in the user
   *
   * @param userLoginData
   * @returns {Promise<AxiosResponse<any>>}
   */
  login(userLoginData) {

    // console.log(userLoginData);

    if (!userLoginData.username) {
      return;
    }

    if (!userLoginData.password) {
      return;
    }

    if (store.state.isLoggedIn && store.state.token) {
      return {
        success: true,
        message: 'User logged in.'
      }
    }

    return mojaApi.post(process.env.VUE_APP_MOJA_API_URL + '/auth', userLoginData, getHeaders())
        .catch( ({response}) => {
            if (response.status >= 400 || response.status <= 500) {
              return response
            } else {
                // console.log(response);
                alert('Oops something crazy has happened please contact support@mojaprepaid.co.za');
            }
          // if (error){
          //   if (error.response.status >= 400 && error.response.status <= 500) {
          //     return {
          //       success: false,
          //       message: error.response.data.message
          //     }
          //   }
          // } else {
          //   console.log(error);
          //   alert('Oops something crazy has happened please contact support@mojaprepaid.co.za');
          // }
          /**
           *           if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
           */
        });
  }

  refreshToken(data) {
    // console.log(data)
    return mojaApi.post(process.env.VUE_APP_MOJA_API_URL + "/auth/refresh", data, getHeaders());
  }

  logout() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/user", getHeaders());
  }

  heartBeat() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/user", getHeaders());
  }

  addNewClientProduct(clientProductData) {
    return mojaApi.post(process.env.VUE_APP_MOJA_API_URL + "/client-product/", clientProductData, getHeaders());
  }

  getAllProducts() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/product", getHeaders());
  }

  getAllTopUpProducts(clientProductId) {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/product/?use=top_up_product&client_product_id=" + clientProductId, getHeaders())
  }

  getProduct(productId) {
    if (!productId) {
      return;
    }
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/product/" + productId, getHeaders());
  }

  getProductTypes() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/product-type", getHeaders());
  }

  getAllVendors() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/vendor", getHeaders());
  }

  updateProduct(productId, data) {
    return mojaApi.put(process.env.VUE_APP_MOJA_API_URL + "/product/" + productId, data, getHeaders());
  }

  addProduct(data) {
    return mojaApi.post(process.env.VUE_APP_MOJA_API_URL + "/product" , data, getHeaders());
  }

  createNewClient(newClient) {
    return mojaApi.post(process.env.VUE_APP_MOJA_API_URL + "/client", newClient, getHeaders())
  }

  getAllClients() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/client", getHeaders())
  }

  getAllUsers() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/user/all", getHeaders())
  }

  createNewUser(userData) {
    return mojaApi.post(process.env.VUE_APP_MOJA_API_URL + "/register/user", userData, getHeaders())
  }

  getUserBtId(userId) {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/register/user/" + userId, getHeaders())
  }

  updateUser(userId, usesrData) {
    return mojaApi.put(process.env.VUE_APP_MOJA_API_URL + "/user/" + userId, usesrData, getHeaders())
  }

  getTopUpRequests(clientProductId) {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/api-request/client-product/" + clientProductId, getHeaders());
  }

  getAllProductTypes() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + '/product-type', getHeaders());
  }

  magicSearch(searchTerm) {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + '/search?search_term=' + searchTerm, getHeaders());
  }

  getClientProducts(clientId) {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + '/client/' + clientId + '/products', getHeaders());
  }

  getClientProductById(clientProductId) {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + '/client-product/' + clientProductId, getHeaders())
  }

  updateClientProduct(clientProductId, data) {
    return mojaApi.put(process.env.VUE_APP_MOJA_API_URL + "/client-product/" + clientProductId, {'client_product': data}, getHeaders());
  }

  getClientProductLogs(clientProductId) {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/client-products-notes/" + clientProductId, getHeaders());
  }

  getClientProductSummaryReport() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/client-product/report/summary", getHeaders());
  }

  getClientProductVouchers(filter = '', perPage, currentPage, sortBy, sortDesc) {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + `/client-product-voucher/?search=${filter.search}&status_id=${filter.status}&cp_status_id=${filter.client_product_status_id}&vendor_id=${filter.vendor_id}&per_page=${perPage}&current_page=${currentPage}&sort_by=${sortBy}&sort_desc=${sortDesc}`, getHeaders());
  }

  getFailedClientProductVoucherReport() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/client-product/report/failed-client-product-vouchers", getHeaders());
  }

  getPendingApprovalClientProductVoucherReport() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/client-product/report/pending-approval-client-product-vouchers", getHeaders());
  }

  getPaystackWebhooks() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/client-product/report/paystack-hooks", getHeaders());
  }

  getClientProductDetailedReport() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/client-product/report/detailed", getHeaders());
  }

  getMainProducts() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/product/?use=main_product", getHeaders());
  }

  deleteProduct(product) {
    return mojaApi.delete(process.env.VUE_APP_MOJA_API_URL + "/product/" + product.id, getHeaders())
  }

  findClientById(clientId) {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/client/" + clientId, getHeaders())
  }

  getStatuses() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/status", getHeaders())
  }

  cancelClientProductVoucher(clientProductVoucherId) {
    return mojaApi.put(process.env.VUE_APP_MOJA_API_URL + "/client-product-voucher/" + clientProductVoucherId + '/cancel',{}, getHeaders())
  }

  retryClientProductVoucher(clientProductVoucherId) {
    return mojaApi.put(process.env.VUE_APP_MOJA_API_URL + "/client-product-voucher/" + clientProductVoucherId + '/retry',{}, getHeaders())
  }

  approveClientProductVoucher(clientProductVoucherId) {
    return mojaApi.put(process.env.VUE_APP_MOJA_API_URL + "/client-product-voucher/" + clientProductVoucherId + '/approve',{'client_solution_id': clientProductVoucherId}, getHeaders())
  }

  getDetailTypes() {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/detail-type", getHeaders())
  }

  addClientProductAttribute(data) {
    return mojaApi.post(process.env.VUE_APP_MOJA_API_URL + "/client-product-attribute" , data, getHeaders());
  }

  getClientProductAttributes(clientProductId) {
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + "/client-product/" + clientProductId + "/client-product-attribute", getHeaders());
  }

  getExpiredClientProductVouchers(voucherNumber = '', sortBy = 'end_date', sortDesc = true, currentPage = 1, perPage = 10) {
    let sort_by = 'end_date';
    if (sortBy) {
      sort_by = sortBy;
    }
    return mojaApi.get(process.env.VUE_APP_MOJA_API_URL + `/client-product-voucher/expired-client-product-vouchers/?voucher_number=${voucherNumber}&per_page=${perPage}&current_page=${currentPage}&sort_by=${sort_by}&sort_desc=${sortDesc}`, getHeaders());
  }
}


export default new MojaAPIService();
