<template>
  <div class="card overflow-hidden h-100">
    <div class="card-body text-center">
      <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif" :class="[cardType]">
        {{ count }}
      </div>
      <h6>
        {{ title }}
        <span class="badge badge-soft-warning rounded-pill ms-2"></span>
      </h6>
      <router-link class="fw-semi-bold fs--1 text-nowrap" :to="{name: 'clientProductsReport', params: {'report_type': routeName}}">
        <small>View</small>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportCard",
  props: ['title', 'count', 'routeName', 'cardType']
}
</script>

<style scoped>

</style>
