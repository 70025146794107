<template>
  <div class="client-product-attributes">
    <b-card class="card" no-body>
      <template #header>
        <div class="card-header-content-between">
          <h4>Client Product Attributes</h4>
          <b-form-group>
            <b-input-group>
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to search"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <b-overlay :show="isLoading" v-if="client_product_attributes">
          <div class="card-body">
            <b-button variant="primary" @click="openClientProductAttributeModal">New Attribute</b-button>
          </div>
          <b-table striped hover
                   :filter="filter"
                   :fields="fields"
                   :items="client_product_attributes"
                   :busy="isLoading"
                   sort-by="id"
                   sort-desc
                   responsive="sm"
                   small
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>

            <template #cell(detail_type)="row">
              {{ row.value.name }}
            </template>

            <template #cell(created_at)="row">
              <StandardDateDisplay :time="row"></StandardDateDisplay>
            </template>

            <template #cell(updated_at)="row">
              <StandardDateDisplay :time="row"></StandardDateDisplay>
            </template>

<!--            <template #cell(action)="row">-->
<!--              {{ row.id }}-->
<!--              <b-button variant="info" size="sm" title="Edit client product attribute">-->
<!--                <b-icon-pencil-square/>-->
<!--              </b-button>-->
<!--            </template>-->
          </b-table>

        </b-overlay>
      </template>

    </b-card>

    <b-modal v-model="isClientProductAttributeModalOpen" title="Add New Client Product Attribute"
             no-close-on-esc
             no-close-on-backdrop>
      <b-overlay :show="isLoading">
        <form>
          <b-form-row>
          <b-col>
            <b-form-group
                label="Detail Types:"
                label-for="detail_type_id"
            >
                <b-form-select
                    id="detail_type_id"
                    value-field="id"
                    text-field="name"
                    v-model="detail_type_id"
                    :options="detailTypes"
                >
                </b-form-select>
<!--                <b-form-invalid-feedback>-->
<!--                  <div v-if="!$v.topUpData.top_up_product_id.required">Voucher validity days number is required.</div>-->
<!--                </b-form-invalid-feedback>-->
            </b-form-group>
            <b-form-group label="Value" label-for="value">
              <b-form-input
                  id="value"
                  type="text"
                  required
                  v-model="detail_type_value"
              ></b-form-input>

<!--              <b-form-invalid-feedback>-->
<!--                <div v-if="!$v.topUpData.voucher_number.required">Voucher number/reference number is required.</div>-->
<!--              </b-form-invalid-feedback>-->
            </b-form-group>
          </b-col>
        </b-form-row>

      </form>
      </b-overlay>

      <template #modal-footer>

        <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="isClientProductAttributeModalOpen=false"
        >
          Close
        </b-button>

        <b-button size="sm" variant="success" @click="addClientProductAttribute()">
          Add
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<style scoped>

</style>

<script>

import StandardDateDisplay from "@/components/StandardDateDisplay.vue";
import MojaAPIService from "@/service/MojaAPIService";

export default {
  name: "ClientProductAttributes",
  components: {StandardDateDisplay},
  props: ['client_product_id'],
  computed: {
  },
  mounted() {
    this.isLoading = true;
    MojaAPIService.getClientProductAttributes(this.$route.params.client_product_id).then(response => {
      this.client_product_attributes = response.data;
    }).finally(() => {
      this.isLoading = false;
    })
  },
  data() {
    return {
      isLoading: false,
      isClientProductAttributeModalOpen: false,
      client_product_attributes: [],
      detailTypes: [],
      filter: null,
      detail_type_id: 0,
      detail_type_value: null,
      fields: [
        { label: 'ID', key: 'id', sortable: true },
        { label: 'Type', key: 'detail_type', sortable: true },
        { label: 'Value', key: 'value', sortable: true },
        { label: 'Created', key: 'created_at', sortable: true },
        { label: 'Updated', key: 'updated_at', sortable: true },
        { label: 'Action', key: 'action', sortable: false },
      ]
    }
  },
  methods: {
    openClientProductAttributeModal() {
      this.isLoading = true;
      this.isClientProductAttributeModalOpen = true;
      this.isClientProductAttributeModalOpen = true;
      MojaAPIService.getDetailTypes().then( response => {
        this.detailTypes = [
            { id: null, name: '-- Select detail type --' },
            ...response.data
        ]
      }).finally(() => {
        this.isLoading = false;
      })
    },
    addClientProductAttribute() {
      const data = {
        'client_product_id' : this.$route.params.client_product_id,
        'detail_type_id': this.detail_type_id,
        'value': this.detail_type_value
      };
      this.isLoading = true;

      MojaAPIService.addClientProductAttribute(data).then((response) => {
        if (response.status === 200) {
          this.isClientProductAttributeModalOpen = false;
          this.detail_type_id = null;
          this.detail_type_value = null;

          MojaAPIService.getClientProductAttributes(this.$route.params.client_product_id).then(response => {
            this.client_product_attributes = response.data;
          })
        }
      }).catch(({response}) => {
        if (response.status >= 400 && response.status <= 500) {
          this.$bvToast.toast(response.data.message, {
            title: 'Oops and error has occurred',
            variant: 'danger',
            solid: true
          });
        }
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
}
</script>