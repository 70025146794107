import { render, staticRenderFns } from "./SingleClientPage.vue?vue&type=template&id=dda659fa&scoped=true&"
import script from "./SingleClientPage.vue?vue&type=script&lang=js&"
export * from "./SingleClientPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dda659fa",
  null
  
)

export default component.exports