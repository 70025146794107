
<template>
  <div class="client-product-vouchers">
    <PageHeader page-title="Client Product Vouchers"></PageHeader>

    <b-card no-body>
      <template #header>
        <div class="card-header-content-between">
          <h4>Products</h4>
          <b-form-group>
            <b-input-group>
              <b-form-input
                  id="filter-input"
                  v-model="filter.search"
                  type="search"
                  placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </template>

      <b-card-body>
        <b-button variant="primary" size="sm">Add New</b-button>
      </b-card-body>

      <b-row>
        <b-col sm="5" md="6" class="my-1">
          <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="page_options"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="5" md="6" class="my-1">
          <b-form-group
              label="Voucher Status"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="filter.status"
                :options="voucher_statuses_options"
                key="id"
                value="name"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
              label="Client Product Status"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="filter.client_product_status_id"
                :options="voucher_statuses_options"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>


        <b-col sm="5" md="6" class="my-1">
          <b-form-group
              label="Vendors"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="filter.vendor_id"
                :options="vendor_options"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>



      {{currentPage}}
      {{rowCount}}
      {{perPage}}


      <b-table striped hover
               :fields="fields"
               :filter="filter"
               :per-page="perPage"
               :current-page="currentPage"
               :items="myProvider"
               :busy="isLoading"
               sort-by.sync="name"
               sort-desc.sync="true"
               responsive="sm"
               small
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>


        <template #cell(voucher_number)="row">
          <router-link target="_blank" :to="{name: 'singleClientProductIdPage', params: {'client_product_id': row.item.client_product.id}}">
            {{ row.value.substring(0, 18) }}
          </router-link>


        </template>

        <template #cell(start_date)="row">
          {{ row.value | moment('YYYY-MM-D H:m') }}
        </template>

        <template #cell(end_date)="row">
          {{ row.value | moment('YYYY-MM-D H:m') }}
        </template>
      </b-table>

      <b-pagination
          v-model="currentPage"
          :total-rows="rowCount"
          :per-page="perPage"
          aria-controls="my-table"
      ></b-pagination>

    </b-card>

  </div>
</template>


<script>
import PageHeader from "@/components/PageHeader.vue";
import mojaAPIService from "@/service/MojaAPIService";

export default {
  name: "ClientProductsVouchers",
  components: {PageHeader},
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      rowCount: 100,
      perPage: 10,
      page_options: [10, 15, 25, { value: 100, text: "Show a lot" }],
      voucher_statuses_options: [
        {value: '', text:  "All"},
        {value: 1, text:  "Active"},
        {value: 6, text:  "Expired"},
        {value: 2, text:  "Pending"},
        {value: 14, text:  "Canceled"},
        {value: 9, text:  "Processed"},
        {value: 4, text:  "Deleted"},
      ],
      vendor_options: [
        {value: '', text:  "All Vendors"},
        {value: 1, text:  "Vuma Reach"},
        {value: 2, text:  "Web Connect"},
        {value: 3, text:  "Web Air"},
        {value: 4, text:  "Web Stream"},
      ],
      items: [],
      filter: {search: '', status: '', client_product_status_id: '', vendor_id: ''},
      fields: [
        {
          key: 'id',
          label: '#',
          sortable: true
        },
        {
          key: 'voucher_number',
          label: 'Voucher Number',
          sortable: true
        },
        {
          key: 'status.name',
          label: 'Voucher Status',
          sortable: false
        },
        {
          key: 'client_product.status.name',
          label: 'Product Status',
          sortable: false
        },
        {
          key: 'product.name',
          label: 'Product',
          sortable: false
        },
        {
          key: 'product.vendor.name',
          label: 'Vendor',
          sortable: false
        },
        {
          key: 'start_date',
          label: 'Start Date',
          sortable: true
        },
        {
          key: 'end_date',
          label: 'End Date',
          sortable: true
        }
      ],
    }
  },
  mounted() {
    // this.getClientProductVouchers().then((response) => {
    //   this.items = response.data.client_product_vouchers;
    // });

  },
  methods: {
    getClientProductVouchers(link) {
      console.log(link);
      return mojaAPIService.getClientProductVouchers()
    },
    async myProvider(ctx) {
      console.log(ctx);
      this.isLoading = true
      try {
        // const response = await this.getClientProductVouchers(`/some/url?page=${ctx.currentPage}&size=${ctx.perPage}&filter=${ctx.filter}`)
        const response = await mojaAPIService.getClientProductVouchers(
            ctx.filter,
            ctx.perPage,
            ctx.currentPage,
            ctx.sortBy,
            ctx.sortDesc
        );

        console.log(response)
        this.isLoading = false

        // this.rowCount = response.data.count;
        this.current_page = response.data.current_page;

        return response.data.client_product_vouchers;
      } catch (error) {
        this.isLoading = false
        return []
      }
    }
  }
}

</script>
