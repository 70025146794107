import store from '../store'
import _ from 'lodash'

export function getHeaders() {
  // let token = '';

  // let token = JSON.parse(localStorage.getItem('token'));
  // if (token) {
  //   token = user.token;
  // }

  return {
    "headers": {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    }
  }
}

export function getUserRoles() {
  return [
      'ROLE_STAFF',
      'ROLE_ADMIN',
      'ROLE_THIRD_PARTY_INTEGRATION',

      'ROLE_CREATE_CLIENT',
      'ROLE_UPDATE_CLIENT',
      'ROLE_DELETE_CLIENT',
      'ROLE_VIEW_CLIENT',

      'ROLE_CREATE_VOUCHER',
      'ROLE_UPDATE_VOUCHER',
      'ROLE_DELETE_VOUCHER',
      'ROLE_VIEW_VOUCHER',

      'ROLE_SUPER_ADMIN',
      'ROLE_CLIENT_MANAGEMENT',
      'ROLE_CLIENT_VOUCHER_MANAGEMENT'
  ];
}

const ROLE_STAFF = 'ROLE_STAFF';
const ROLE_ADMIN = 'ROLE_ADMIN';
const ROLE_SUPER_USER = 'ROLE_SUPER_USER';
const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';

export function isSuperAdmin() {
  switch (true) {
    case _.includes(store.state.loggedUser.roles, ROLE_SUPER_USER):
    case _.includes(store.state.loggedUser.roles, ROLE_STAFF):
    case _.includes(store.state.loggedUser.roles, ROLE_SUPER_ADMIN):
      return true;
    default:
      return false;
  }
}

export function isAdmin() {
  switch (true) {
    case _.includes(store.state.loggedUser.roles, ROLE_ADMIN):
    case _.includes(store.state.loggedUser.roles, ROLE_STAFF):
      case _.includes(store.state.loggedUser.roles, ROLE_SUPER_ADMIN):
      return true;
    default:
      return false;
  }
}

export function isStaff() {
    switch (true) {
        case _.includes(store.state.loggedUser.roles, ROLE_ADMIN):
        case _.includes(store.state.loggedUser.roles, ROLE_STAFF):
        case _.includes(store.state.loggedUser.roles, ROLE_SUPER_ADMIN):
            return true;
        default:
            return false;
    }
}

