<template>
  <div class="new-product-form">
    <b-form-row>
      <b-col>
        <b-form-group
          id="name-input"
          label="Product Name"
          label-for="product_name"
          description=""
        >
          <b-form-input
            id="product_name"
            type="text"
            placeholder=""
            :class="{'is-invalid': $v.newProductForm.name.$error}"
            v-model.trim="$v.newProductForm.name.$model"
          ></b-form-input>

          <b-form-invalid-feedback>
            <div v-if="!$v.newProductForm.name.required">Product name is required.</div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
          id="description-input"
          label="Description:"
          label-for="product_description"
        >
          <b-form-textarea
            id="product_description"
            type="text"
            placeholder=""
            :class="{'is-invalid': $v.newProductForm.description.$error}"
            v-model.trim="$v.newProductForm.description.$model"
          ></b-form-textarea>

          <b-form-invalid-feedback>
            <div v-if="!$v.newProductForm.description.required">Product description is required.</div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
          id="description-input"
          label="Product Code:"
          label-for="product_fsan_number"
        >
          <b-form-input
            id="product_fsan_number"
            type="text"
            placeholder=""
            :class="{'is-invalid': $v.newProductForm.fsan_number.$error}"
            v-model.trim="$v.newProductForm.fsan_number.$model"
            required
          ></b-form-input>

          <b-form-invalid-feedback>
            <div v-if="!$v.newProductForm.description.required">Product number is required.</div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
          id="cost-input"
          label="Cost:"
          label-for="product_cost"
        >
          <b-form-input
            id="product_cost"
            type="text"
            placeholder=""
            :class="{'is-invalid': $v.newProductForm.cost.$error}"
            v-model.trim="$v.newProductForm.cost.$model"
            required
          ></b-form-input>

          <b-form-invalid-feedback>
            <div v-if="!$v.newProductForm.cost.required">Product cost is required.</div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
          id="product-type-input"
          label="Active Days:"
          label-for="product_active_days_id"
        >
          <b-form-input
            id="product_active_days_id"
            type="text"
            placeholder=""
            :class="{'is-invalid': $v.newProductForm.active_days.$error}"
            v-model.trim="$v.newProductForm.active_days.$model"
            required
          ></b-form-input>

          <b-form-invalid-feedback>
            <div v-if="!$v.newProductForm.active_days.required">Product active days is required.</div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
          id="product-type-input"
          label="Type:"
          label-for="product_product_type_id"
        >
          <b-form-select
            id="product_product_type_id"
            type="text"
            placeholder=""
            :class="{'is-invalid': $v.newProductForm.product_type_id.$error}"
            v-model.trim="$v.newProductForm.product_type_id.$model"
            value-field="id"
            text-field="name"
            :options="productTypes"
          >
            <option value="">-- Select product type --</option>
          </b-form-select>

          <b-form-invalid-feedback>
            <div v-if="!$v.newProductForm.product_type_id.required">Product product type is required.</div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
          id="product-vendor-input"
          label="Vendor:"
          label-for="product_vendor_id"
        >
          <b-form-select
            id="product_vendor_id"
            type="text"
            placeholder=""
            value-field="id"
            text-field="name"
            :options="vendors"
            :class="{'is-invalid': $v.newProductForm.vendor_id.$error}"
            v-model.trim="$v.newProductForm.vendor_id.$model"
          >
            <option value="">-- Select Product Vendor --</option>
          </b-form-select>

          <b-form-invalid-feedback>
            <div v-if="!$v.newProductForm.vendor_id.required">Product vendor is required.</div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-radio-group
            :class="{'is-invalid': $v.newProductForm.product_use.$error}"
            v-model.trim="$v.newProductForm.product_use.$model"
            :options="options"
            class="mb-3"
            value-field="value"
            text-field="item"
        ></b-form-radio-group>

      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: "NewProductForm",
  props: {
    newProductForm: {
      required: true
    },
    vendors: {
      required: true
    },
    productTypes: {
      required: true
    }
  },
  data() {
    return {
      options: [
        { item: 'Main Product', value: 'main_product' },
        { item: 'Top Up Product', value: 'top_up_product' },
      ]
    }
  },
  mounted() {
  },
  validations: {
    newProductForm: {
      name: {
        required
      },
      description: {
        required
      },
      cost: {
        required
      },
      fsan_number: {
        required
      },
      product_type_id: {
        required
      },
      active_days: {
        required: required
      },
      vendor_id: {
        required
      },
      product_use: {
        required
      }
    }
  },
}
</script>

<style scoped>

</style>
