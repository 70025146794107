import MojaAPIService from "@/service/MojaAPIService";

const clientProduct = {
  namespaced: true,

  state: {

  },
  mutations: {

  },
  actions: {
    createNewClientProduct(context, payload) {
      return MojaAPIService.addNewClientProduct(payload);
    }
  }
};

export default clientProduct;
