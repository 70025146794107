<template>
  <div class="expired-client-product-vouchers">
    <PageHeader page-title="Expired Client Product Vouchers"></PageHeader>

    <b-card no-body>
      <template #header>
        <div class="card-header-content-between">
          <h4>Products</h4>
          <b-form-group>
            <b-input-group>
              <b-form-input
                  id="filter-input"
                  v-model="filter.search"
                  type="search"
                  placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </template>

      <b-card-body>
<!--        <b-button variant="primary" size="sm">Add New</b-button>-->
      </b-card-body>

      <b-row>
        <b-col sm="5" md="6" class="mb-4">
          <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="page_options"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
<!--        <b-col sm="5" md="6" class="my-1">-->
<!--          <b-form-group-->
<!--              label="Voucher Status"-->
<!--              label-for="per-page-select"-->
<!--              label-cols-sm="6"-->
<!--              label-cols-md="4"-->
<!--              label-cols-lg="3"-->
<!--              label-align-sm="right"-->
<!--              label-size="sm"-->
<!--              class="mb-0"-->
<!--          >-->
<!--            <b-form-select-->
<!--                id="per-page-select"-->
<!--                v-model="filter.status"-->
<!--                :options="voucher_statuses_options"-->
<!--                key="id"-->
<!--                value="name"-->
<!--                size="sm"-->
<!--            ></b-form-select>-->
<!--          </b-form-group>-->
<!--        </b-col>-->

<!--        <b-col sm="5" md="6" class="my-1">-->
<!--          <b-form-group-->
<!--              label="Client Product Status"-->
<!--              label-for="per-page-select"-->
<!--              label-cols-sm="6"-->
<!--              label-cols-md="4"-->
<!--              label-cols-lg="3"-->
<!--              label-align-sm="right"-->
<!--              label-size="sm"-->
<!--              class="mb-0"-->
<!--          >-->
<!--            <b-form-select-->
<!--                id="per-page-select"-->
<!--                v-model="filter.client_product_status_id"-->
<!--                :options="voucher_statuses_options"-->
<!--                size="sm"-->
<!--            ></b-form-select>-->
<!--          </b-form-group>-->
<!--        </b-col>-->


<!--        <b-col sm="5" md="6" class="my-1">-->
<!--          <b-form-group-->
<!--              label="Vendors"-->
<!--              label-for="per-page-select"-->
<!--              label-cols-sm="6"-->
<!--              label-cols-md="4"-->
<!--              label-cols-lg="3"-->
<!--              label-align-sm="right"-->
<!--              label-size="sm"-->
<!--              class="mb-0"-->
<!--          >-->
<!--            <b-form-select-->
<!--                id="per-page-select"-->
<!--                v-model="filter.vendor_id"-->
<!--                :options="vendor_options"-->
<!--                size="sm"-->
<!--            ></b-form-select>-->
<!--          </b-form-group>-->
<!--        </b-col>-->
      </b-row>

      <b-table striped hover
               :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc"
               :fields="fields"
               :filter="filter"
               :per-page="perPage"
               :current-page="currentPage"
               :items="myProvider"
               :busy="isLoading"
               responsive="sm"
               small
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>


        <template #cell(voucher_number)="row">
          <router-link target="_blank" :to="{name: 'singleClientProductIdPage', params: {'client_product_id': row.item.client_product_id}}">
            {{ row.value.substring(0, 18) }}
          </router-link>
        </template>

        <template #cell(active_voucher)="row">
          <router-link target="_blank" :to="{name: 'singleClientProductIdPage', params: {'client_product_id': row.item.client_product_id}}">
            {{ row.item.active_voucher }}
          </router-link>
        </template>

<!--        <template #cell(start_date)="row">-->
<!--          {{ row.value | moment('YYYY-MM-D H:m') }}-->
<!--        </template>-->

<!--        <template #cell(action)="row">-->
<!--          {{ row.item.id}}-->
<!--          <b-button>View</b-button>-->
<!--        </template>-->
      </b-table>

      <b-pagination
          v-model="currentPage"
          :total-rows="rowCount"
          :per-page="perPage"
          aria-controls="my-table"
      ></b-pagination>

    </b-card>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import MojaAPIService from "@/service/MojaAPIService";

export default {
  components: {PageHeader},
  data() {
    return {
      isLoading: false,
      sortBy: 'end_date',
      sortDesc: true,
      currentPage: 1,
      rowCount: 100,
      perPage: 10,
      items: [],
      page_options: [10, 15, 25, { value: 100, text: "Show a lot" }],
      filter: {search: '', status: '', client_product_status_id: '', vendor_id: ''},
      fields: [
        { label: 'ID', key: 'id', sortable: true },
        { label: 'Client', key: 'first_name', sortable: true },
        { label: 'Voucher', key: 'voucher_number', sortable: true },
        { label: 'Start', key: 'start_date', sortable: true },
        { label: 'End', key: 'end_date', sortable: true },
        { label: 'Notification', key: 'status', sortable: true },
        { label: 'Active Voucher', key: 'active_voucher', sortable: true },
        // { label: 'Action', key: 'action', sortable: false },
      ],
      vendor_options: [],
      voucher_statuses_options: [
        {value: '', text:  "All"},
        {value: 1, text:  "Active"},
        {value: 6, text:  "Expired"},
        {value: 2, text:  "Pending"},
        {value: 14, text:  "Canceled"},
        {value: 9, text:  "Processed"},
        {value: 4, text:  "Deleted"},
      ],
    }
  },
  mounted() {
  },
  methods: {
    async myProvider(ctx) {
      console.log(ctx)
      this.isLoading = true;
      try {
        const res = await MojaAPIService.getExpiredClientProductVouchers(
            ctx.filter.search,
            ctx.sortBy,
            ctx.sortDesc,
            ctx.currentPage,
            ctx.perPage
        );
        // console.table(res.data.expired_client_product_vouchers)

        this.isLoading = false;

        return res.data.expired_client_product_vouchers;
      } catch (error) {
        this.isLoading = false
        return []
      }
    }
  }
}

</script>

<style scoped>

</style>