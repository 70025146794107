import MojaAPIService from "@/service/MojaAPIService";

const clientProductVoucher = {
  namespaced: true,

  state: {

  },
  mutations: {

  },
  actions: {
    cancelClientProductVoucher(context, clientProductVoucherId) {
      return MojaAPIService.cancelClientProductVoucher(clientProductVoucherId);
    }
  }
};

export default clientProductVoucher;
