<template>
  <div class="product-list">
    <PageHeader page-title="Manage Products"></PageHeader>

    <b-card no-body>
      <template #header>
        <div class="card-header-content-between">
          <h4>Products</h4>
          <b-form-group>
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </template>

      <b-card-body>
        <b-button variant="primary" @click="openAddProductModel" size="sm">Add New</b-button>
      </b-card-body>

      <b-table striped hover :fields="fields"
               :filter="filter" :items="products" :busy="isLoading"
               sort-by.sync="name"
               sort-desc.sync="true"
               responsive="sm"
               small
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>

        <template #cell(cost)="row">
         R {{ row.value }}
        </template>

        <template #cell(vendor)="row">
          {{ row.value.name }}
        </template>

        <template #cell(product_type)="row">
          {{ row.value.name }}
        </template>

        <template #cell(created_at)="row">
          {{ row.value | moment('YYYY-MM-D H:m') }}
        </template>

        <template #cell(updated_at)="row">
          {{ row.value | moment('YYYY-MM-D H:m') }}
        </template>

        <template #cell(action)="row">
          <b-button size="sm" @click="openEditProductModel(row.item.id)" class="btn btn-primary mr-2">
            View
          </b-button>
          <b-button variant="danger" size="sm" @click.prevent="deleteProductConfirmationModal(row.item)">Delete</b-button>
        </template>
      </b-table>

    </b-card>

    <b-modal v-model="newProductModel" size="xl" title="New Product"
             no-close-on-esc
             no-close-on-backdrop>
      <b-form @submit.prevent="">
      <NewProductForm
        :new-product-form="newProductForm"
        :product-types="productTypes"
        :vendors="vendors"
      ></NewProductForm>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="newProductModel=false"
        >
          Close
        </b-button>

        {{product}}
        <b-button size="sm" variant="danger" @click="updateProduct(newProductForm.id)">
          Save
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="editProductModel" size="xl" title="Update Product"

             no-close-on-esc
             no-close-on-backdrop>
      <b-form @submit.prevent="">
        <NewProductForm
          :new-product-form="newProductForm"
          :product-types="productTypes"
          :vendors="vendors"
        ></NewProductForm>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="newProductModel=false"
        >
          Close
        </b-button>

        <b-button size="sm" variant="success" @click="createNewProduct(newProductForm.id)">
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import MojaAPIService from "@/service/MojaAPIService";
import NewProductForm from "@/components/Product/NewProductForm";
export default {
  name: "ProductListPage",
  components: {NewProductForm, PageHeader},
  data() {
    return {
      products: [],
      isLoading: true,
      filter: null,
      newProductModel: false,
      editProductModel: false,
      product: null,
      productTypes: [],
      vendors: [],
      newProductForm: {
        name: '',
        description: '',
        cost: 0.00,
        fsan_number: '',
        product_type_id: '',
        active_days: null,
        vendor_id: '',
        product_use: 'main_product'
      },
      fields: [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        'cost',
        'vendor',
        'product_type',
        'active_days',
        {key: 'created_at', sortable: true},
        'action'
      ],

    }
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.isLoading = true;
      MojaAPIService.getAllProducts().then( response => {
        if (response.status === 200) {
          this.products = response.data
          this.isLoading = false
        }
      });
    },
    openEditProductModel(productId) {
      if (!productId) {
        return;
      }

      MojaAPIService.getProduct(productId).then( response => {
        if (response.status !== 200) {
          return;
        }

        this.newProductForm = {
          ...response.data,
          status_id: response.data.status.id,
          vendor_id: response.data.vendor.id,
        };

        MojaAPIService.getProductTypes().then( response => {
          if (response.status === 200) {

            this.productTypes = response.data

            MojaAPIService.getAllVendors().then( response => {
              if (response.status !== 200) {
                return;
              }
              this.vendors = response.data;
            });
            this.newProductModel = true
          }
        });

      });
    },

    openAddProductModel() {
      MojaAPIService.getProductTypes().then( response => {
        if (response.status === 200) {

          this.productTypes = response.data

          MojaAPIService.getAllVendors().then( response => {
            if (response.status !== 200) {
              return;
            }
            this.vendors = response.data;
          });

          this.newProductForm = {
            name: null,
            description: null,
            cost: 0.00,
            fsan_number: null,
            product_type_id: null,
            active_days: null,
            status_id: null,
            vendor_id: null,
          };

          this.editProductModel = true
        }
      });
    },

    updateProduct(productId) {
      if (!productId) {
        return;
      }

      // if (this.$v.newProductForm.$invalid ) {
      //   return;
      // }
      // console.log(this.newProductForm);
      MojaAPIService.updateProduct(productId, this.newProductForm).then( response => {
        if (response.status === 200) {

          this.getProducts();

          this.newProductModel = false
        }
      });
    },

    createNewProduct() {
      MojaAPIService.addProduct(this.newProductForm).then( response => {

        if (response.status === 200) {

          this.getProducts();

          this.editProductModel = false
        } else if (response.status >= 400) {
          this.$bvToast.toast(response.data.message, {
            title: 'Oops and error has occurred',
            variant: 'danger',
            solid: true
          })
        }
      }).catch( error => {
        console.log(error)
      });
    },

    deleteProductConfirmationModal(product) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete ' + product.name, {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        cancelTitle: 'Cancel',
        cancelVariant: 'secondary',
        okVariant: 'danger',
        okTitle: 'Okay',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
      .then((result) => {
        if (result === true) {
          MojaAPIService.deleteProduct(product).then((result) => {
            console.log('result', result);

            this.getProducts();

            this.$bvToast.toast(result.data.message, {
              title: 'Oops and error has occurred',
              variant: 'success',
              solid: true
            })


          })
          .catch( ({response}) => {
            this.$bvToast.toast(response.data.message, {
              title: 'Oops and error has occurred',
              variant: 'danger',
              solid: true
            })
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
