<template>
  <div class="login-form">
    <b-card no-body class="mt-5 card-body">
      <img class="login-logo" :src="'logo.png'">
      <form @submit.prevent="onSubmit" autocomplete="off">
        <b-form-row>
          <b-col>
            <b-form-group label="Username:" label-for="username">
              <b-form-input
                id="username"
                type="text"
                placeholder=""
                :class="{'is-invalid': $v.loginForm.username.$error}"
                v-model.trim="$v.loginForm.username.$model"
              ></b-form-input>

              <b-form-invalid-feedback>
                <div v-if="!$v.loginForm.username.required">Username is required.</div>
                <div v-if="!$v.loginForm.username.minLength">Username is required to be no less than 4 characters.</div>
                <div v-if="!$v.loginForm.username.email">Username needs to be a valid email address.</div>
              </b-form-invalid-feedback>

            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group label="Password:" label-for="password">
              <b-form-input
                id="password"
                type="password"
                placeholder=""
                required
                v-model.trim="$v.loginForm.password.$model"
                :class="{'is-invalid': $v.loginForm.password.$error}"
              ></b-form-input>

              <b-form-invalid-feedback>
                <div v-if="!$v.loginForm.password.required">Password is required.</div>
                <div v-if="!$v.loginForm.password.minLength">Password is required to be no less than 8 characters.</div>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-alert :variant="messageType" :show="showMessage">
           {{ message }}
        </b-alert>

        <b-button variant="primary" class="mr-2" type="submit" :disabled="$v.loginForm.$invalid">Login</b-button>
        <span v-if="isLoading">Loading...</span>
        <!-- <b-button variant="link" class="mr-2">Reset password</b-button>-->
      </form>
    </b-card>

  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import mojaAPIService from "@/service/MojaAPIService";
import router from "@/router";
import MojaAPIService from "@/service/MojaAPIService";

export default {
  name: "LoginForm",
  data() {
    return {
      isLoading: false,
      showMessage: false,
      message: null,
      messageType: 'info',
      loginForm: {
        username: "",
        password: "",
      },
    }
  },
  validations: {
    loginForm: {
      username: {
        required,
        email,
        minLength: minLength(4)
      },
      password: {
        required,
        minLength: minLength(5)
      }
    }
  },
  methods: {
    onSubmit() {
     if (!this.$v.loginForm.$invalid) {
       const loginData = {
         'username': this.$v.loginForm.username.$model,
         'password': this.$v.loginForm.password.$model
       };

       this.message = null;
       this.messageType = null;
       this.showMessage = false;

       this.isLoading = true;
       mojaAPIService.login(loginData).then((response) => {
         console.log(response.status)
         if (response.status === 401) {
           this.message = 'Username and password do not match.';
           this.messageType = 'danger';
           this.showMessage = true;
           return;
         }

         if (response.status === 200) {
           localStorage.setItem('token', response.data.token);
           localStorage.setItem('refresh_token', response.data.refresh_token);
           this.$store.state.token = response.data.token;
           this.$store.state.refreshToken = response.data.refresh_token;

           MojaAPIService.heartBeat().then((response) => {
             if (response.status === 200) {
               console.log( response.data);
               this.$store.state.loggedUser = response.data;
               this.$store.state.isOpen = true;
               localStorage.setItem('_user',  JSON.stringify(response.data));
               router.push('/dashboard');
             }
           });
         }
       }).finally(() => {
         this.isLoading = false;
       })

       // this.$store.dispatch('logIn', loginData).then( response => {
       //     this.showMessage = false;
       //     if (response.status === 200) {
       //       this.$router.push('/dashboard');
       //       return;
       //     } else {
       //       this.message = 'Username and password do not match.';
       //       this.messageType = 'danger';
       //       this.showMessage = true;
       //       return;
       //     }
       // })
     }
    },
  }
}
</script>

<style lang="scss" scoped>
.login-page {

  .login-logo {
    width: 100%;
  }

  .login-form {
    width: 450px;
  }
}
</style>
