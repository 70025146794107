<template>
<div class="login-page">
  <LoginForm></LoginForm>
</div>
</template>

<script>
import LoginForm from "@/components/User/LoginForm";
export default {
  name: "LoginPage",
  components: {LoginForm},
}
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  justify-content: center;
}
</style>
