<template>
  <div class="basic-information">
    <b-card class="card" no-body>
      <!-- Table Filter -->
      <template #header>
        <div class="card-header-content-between">
          <h4>Products</h4>
          <b-form-group>
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''" v-if="isAdmin">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </template>
      <!-- Table Filter End -->

      <div class="card-body">
        <b-button variant="primary" size="sm" @click="openNewClientProductWindow()" v-if="isStaff">New Product</b-button>
      </div>

      <!-- Product Table -->
      <b-table striped hover
               :fields="fields"
               :filter="filter"
               :items="products"
               :busy="isLoading"
               sort-by.sync="id"
               sort-desc.sync="true"
               responsive="sm"
               small
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>

        <template #cell(action)="row">
          <b-button size="sm" :to="{name: 'singleClientProductIdPage', params: {'client_product_id': row.item.id}}" v-if="isStaff">Manage</b-button>
        </template>

        <template #cell(status)="row">
          <StatusBadge :status="row.value"></StatusBadge>
        </template>

        <template #cell(created_at)="row">
          <StandardDateDisplay :time="row.value"></StandardDateDisplay>
        </template>

        <template #cell(updated_at)="row">
          <StandardDateDisplay :time="row.value"></StandardDateDisplay>
        </template>
      </b-table>
      <!-- Product Table End -->
    </b-card>

    <!-- New Client Product Card -->
    <b-modal v-model="showNewClientProductWizard" size="xl" title="New Product Wizard"
             no-close-on-esc
             no-close-on-backdrop>

      <!-- New Client Product Form -->
      <form @submi.p.capture="createNewProduct" autocomplete="off">
        <b-form-row>

          <b-col>
            <b-form-group
                id="composite-client-solution-id-input"
                label="Friendly Name"
                label-for="friendly_name"
                description="Helps you identify the product easily"
            >
              <b-form-input
                  id="vendor_uid"
                  type="text"
                  placeholder="Mom's houses fibre package"
                  :class="{'is-invalid': $v.newClientProduct.friendly_name.$error}"
                  v-model.trim="$v.newClientProduct.friendly_name.$model"
              ></b-form-input>

              <b-form-invalid-feedback>
                <div v-if="!$v.newClientProduct.friendly_name.maxLength">Friendly name should not exceed 10 characters</div>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
                id="composite-client-solution-id-input"
                label="Vendor UID"
                label-for="vendor_uid"
                description="3rd Party unique identifier composite client solution id"
            >
              <b-form-input
                  id="vendor_uid"
                  type="text"
                  placeholder="10201410"
                  :class="{'is-invalid': $v.newClientProduct.vendor_uid.$error}"
                  v-model.trim="$v.newClientProduct.vendor_uid.$model"
              ></b-form-input>

              <b-form-invalid-feedback>
                <div v-if="!$v.newClientProduct.vendor_uid.required">UID id is required.</div>
                <div v-if="!$v.newClientProduct.vendor_uid.minLength">Doesnt seem like a valid UID.</div>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
                id="product-input"
                label="Product:"
                label-for="product"
            >
              <b-form-select
                  id="product_id"
                  value-field="id"
                  text-field="name"
                  :options="productOptions"
                  :class="{'is-invalid': $v.newClientProduct.product_id.$error}"
                  v-model.trim="$v.newClientProduct.product_id.$model"
              >
                <option value="">-- Select Product --</option>
              </b-form-select>

              <b-form-invalid-feedback>
                <div v-if="!$v.newClientProduct.product_id.required">Product type is required.</div>
                <div v-if="!$v.newClientProduct.product_id.numeric">Only numbers allowed</div>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
      </form>
      <!-- New Client Product Form End -->

      <!-- Model Footer -->
      <template #modal-footer>
        <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="showNewClientProductWizard=false"
        >
          Close
        </b-button>
        <b-button size="sm" variant="success" @click="createNewProduct" :disabled="$v.newClientProduct.$invalid">
          Save
        </b-button>
      </template>
      <!-- Model Footer End -->
    </b-modal>
    <!-- New Client Product Card End -->
  </div>
</template>

<script>
import StatusBadge from "@/components/StatusBadge";
import StandardDateDisplay from "@/components/StandardDateDisplay";
import {numeric, minLength, maxLength, required} from "vuelidate/lib/validators";
import { isAdmin, isStaff } from "@/helpers/api.helper";
import MojaAPIService from "@/service/MojaAPIService";

export default {
  name: "ClientProducts",
  components: {StandardDateDisplay, StatusBadge},
  props: {
    client_id: {
      required: true,
    }
  },
  computed: {
    isAdmin() {
      return isAdmin()
    },
    isStaff() {
      return isStaff()
    }
  },
  mounted() {
    this.isLoading = true;
    MojaAPIService.getClientProducts(this.client_id).then( response => {
      if (response.status === 200) {
        this.products = response.data;
      }
      this.isLoading = false;
    });

    MojaAPIService.getAllProductTypes().then( (response) => {
      if (response.status === 200) {
        this.productTypeOptions = response.data;
      }
    });

    MojaAPIService.getStatuses().then(response => {
      this.statusOptions = response.data
    }).catch( ({response}) => {
      this.$bvToast.toast(response.data.message, {
        title: 'Oops and error has occurred',
        variant: 'danger',
        solid: true
      });
    })
  },
  data() {
    return {
      isLoading: true,
      filter: null,
      clientId: null,
      productTypeOptions: [],
      productOptions: [],
      statusOptions: [],
      newClientProduct: {
        product_id: '',
        friendly_name: '',
        vendor_uid: null
      },
      showNewClientProductWizard: false,
      fields: [
        { label: 'ID', key: 'id', sortable: true },
        { label: 'Friendly Name',key: 'friendly_name', sortable: false },
        { label: 'Name',key: 'product.name', sortable: false },
        { label: 'Status',key: 'status', sortable: true },
        { label: 'Created',key: 'created_at', sortable: true },
        { label: 'Action', key: 'action', sortable: false }
      ],
      products: []
    }
  },
  validations: {
    newClientProduct: {
      vendor_uid: {
        required,
        minLength: minLength(4)
      },
      friendly_name: {
        maxLength: maxLength(20)
      },
      product_id: {
        required,
        numeric
      }
    }
  },
  methods: {
    openNewClientProductWindow() {
      this.isLoading = true;
      this.getProducts().then(response => {
        if (response.status === 200) {
          this.productOptions = response.data;
          this.showNewClientProductWizard=true;
          this.isLoading = false;
        }
      });
    },

    createNewProduct() {
      if (!this.$v.newClientProduct.$invalid) {
        const data = {
          product_id: this.$v.newClientProduct.product_id.$model,
          friendly_name: this.$v.newClientProduct.friendly_name.$model,
          vendor_uid: this.$v.newClientProduct.vendor_uid.$model,
          client_id: this.client_id
        }

        this.$store.dispatch('clientProduct/createNewClientProduct', data).then(response => {
          if (response.status === 200) {
            this.$bvToast.toast('New Product Add Successfully', {
              title: 'New Product Add Successfully',
              variant: 'success',
              solid: true
            });

            this.showNewClientProductWizard = false
            this.isLoading = true;
            MojaAPIService.getClientProducts(this.client_id).then( response => {
              if (response.status === 200) {
                this.products = response.data;
              }
              this.isLoading = false;
            });

          }

          this.showNewClientProductWizard = false
        }).catch(({response}) => {
          console.log('createNewProduct', response);
          this.$bvToast.toast(response.data.message, {
            title: 'Oops and error has occurred',
            variant: 'danger',
            solid: true
          })
        })
      }
    },

    getProducts() {
      return MojaAPIService.getMainProducts()
    },
  }
}
</script>

<style scoped>

</style>
