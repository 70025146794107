import Vue from 'vue'
import Vuex from 'vuex'
import MojaAPIService from "@/service/MojaAPIService";
import clientProduct from "@/store/client-product.module";
import clientProductVoucher from "@/store/client-product-voucher.module";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: true,
    loggedUser: JSON.parse(localStorage.getItem('_user')) || {},
    isOpen: true,
    token: localStorage.getItem('token') || null,
    refreshToken: localStorage.getItem('refresh_token') || null
  },
  getters: {
    isLoggedIn(state) {
      if (state.token && state.refreshToken) {
        return true;
      }

      return false;
    },
    getLoggedInUser(state) {
      return state.loggedUser;
    }
  },
  mutations: {
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload
    },
    // SET_IS_LOGGED_IN(state) {
    //   state.isLoggedIn = true
    // },
    // LOG_USER_IN(state) {
    //   state.isLoggedIn = true;
    // },
    LOG_USER_OUT(state) {
      localStorage.removeItem('_user');
      state.isLoggedIn = false;
      state.token = null;
      state.refreshToken = null;
      state.isOpen = false;
      state.loggedUser = {};
    },
    SET_LOGGED_IN_USER(state, payload) {
      state.loggedUser = payload
      state.isLoggedIn = true;
      state.isOpen = true;
      localStorage.setItem('_user', JSON.stringify(payload))
    },
    SET_TOKEN(state, payload) {
      state.token = payload
      localStorage.setItem('token', payload)
    },
    SET_REFRESH_TOKEN(state, payload) {
      state.refreshToken = payload
      localStorage.setItem('refresh_token', payload)
    },
    CLEAR_TOKEN() {
      localStorage.removeItem('token')
    },
    CLEAR_REFRESH_TOKEN() {
      localStorage.removeItem('refresh_token')
    }
  },
  actions: {
    logIn(context, loginData) {
      MojaAPIService.heartBeat().then( response => {
        if (response.status === 200) {
          context.commit('SET_LOGGED_IN_USER', response.data);
          context.commit('SET_IS_LOADING', true);
          context.commit('SET_TOKEN', loginData.token);
          context.commit('SET_REFRESH_TOKEN', loginData.refresh_token);
          context.commit('SET_IS_LOADING', false);
        }
      });


      context.commit('SET_IS_LOADING', true);
      // return MojaAPIService.login(loginData).then( response => {
        // console.log(response);
        // if (response.status === 200) {
          context.commit('SET_TOKEN', loginData.token);
          context.commit('SET_REFRESH_TOKEN', loginData.refresh_token);
          // MojaAPIService.heartBeat().then( response => {
          //   if (response.status === 200) {
          //     context.commit('SET_LOGGED_IN_USER', response.data);
          //   }
          // });
        // }
        context.commit('SET_IS_LOADING', false);
        // return response;
      // });
    },
    logOut(context) {
      context.commit('CLEAR_REFRESH_TOKEN');
      context.commit('CLEAR_TOKEN');
      context.commit('SET_IS_LOADING', false);
      context.commit('LOG_USER_OUT');
    },
    setLoggedInUser(context, payload) {
      context.commit('SET_LOGGED_IN_USER', payload)
    },
    refreshToken(context, refreshKey) {
      context.commit('SET_IS_LOADING', true);
      MojaAPIService.refreshToken({'refresh_token': refreshKey}).then( response => {
          if (response.status === 200) {
            context.commit('SET_TOKEN', response.data.token)
            context.commit('SET_REFRESH_TOKEN', response.data.token)

            context.commit('SET_IS_LOADING', false);
            console.log('token refreshed')
          } else {
            context.commit('CLEAR_TOKEN')
            context.commit('CLEAR_REFRESH_TOKEN')
            context.commit('LOG_USER_OUT')
            console.log('logout')
          }

          return response;
      })
      // return MojaAPIService.refreshToken({'refresh_token': refreshKey}).then(response => {
      //   console.log('response -----------------');
      //   if (response.status === 200) {
      //     context.commit('SET_TOKEN', response.data.token)
      //     context.commit('SET_REFRESH_TOKEN', response.data.token)
      //
      //     context.commit('SET_IS_LOADING', false);
      //   } else {
      //     context.commit('CLEAR_TOKEN')
      //     context.commit('CLEAR_REFRESH_TOKEN')
      //     context.commit('LOG_USER_OUT')
      //   }
      //   return response;
      // }).catch((err) => {
      //     // An error occurred
      //     console.error(err)
      //     console.error('err ----------------')
      //     context.commit('CLEAR_TOKEN')
      //     context.commit('CLEAR_REFRESH_TOKEN')
      //     context.commit('LOG_USER_OUT')
      // });
    }
  },
  modules: {
    clientProduct: clientProduct,
    clientProductVoucher: clientProductVoucher
  }
})
