<template>
  <div class="basic-information">
    <b-card class="card" no-body>
      <template #header>
        <div class="card-header-content-between">
          <h4>Basic Information</h4>
          <div>
            <b-badge variant="success" v-if="client.status_id === 1">Active</b-badge>
            <b-badge variant="warning" v-if="client.status_id === 5">Suspended</b-badge>
            <b-badge variant="info" v-if="client.status_id === 2">Pending</b-badge>
          </div>
        </div>
      </template>

      <b-overlay :show="isLoading">
          <div class="card-body">
            <form>
              <b-form-row>
                <b-col>
                  <b-form-group label="First Name:" label-for="first_name">
                    <b-form-input
                      id="first_name"
                      type="text"
                      placeholder="Jone"
                      required
                      v-model="client.first_name"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Last Name:" label-for="last_name">
                    <b-form-input
                      id="last_name"
                      type="text"
                      placeholder="Doe"
                      required
                      v-model="client.last_name"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group label="Email Address:" label-for="email_address">
                    <b-form-input
                      id="email_address"
                      type="email"
                      placeholder="jone@example.co.za"
                      required
                      v-model="client.email"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Contact Number:" label-for="contact_number">
                    <b-form-input
                      id="contact_number"
                      type="text"
                      placeholder="011 234 56789"
                      required
                      v-model="client.contact_number"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group label="Created Date:" label-for="created_date">
                    <b-form-input
                      id="signup_date"
                      type="text"
                      placeholder=""
                      required
                      v-model="client.created_at"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Updated:" label-for="updated_date">
                    <b-form-input
                      id="updated_date"
                      type="text"
                      placeholder=""
                      required
                      v-model="client.updated_at"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <!-- admin person
              -->
              <b-form-row v-if="isSuperAdmin">
                <b-form-group label="Status:" label-for="status">
                  <b-form-select
                    id="status_id"
                    value-field="id"
                    text-field="name"
                    :options="statusOptions"
                    required
                    v-model="client.status_id"
                  >
                    <option value="0">-- Select status --</option>
                  </b-form-select>
                </b-form-group>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-button variant="secondary" class="mr-2" :to="{name: 'clients'}">Back</b-button>
                  <span v-if="client.status">
                    <b-button variant="success" class="mr-2" v-if="client" @click="updateClient()">Save</b-button>
                    <b-button variant="warning" class="mr-2" v-if="client.status_id === 1 && isSuperAdmin" @click="suspendClient()">Suspend</b-button>
                    <b-button variant="primary" class="mr-2" v-if="client.status_id === 5 && isSuperAdmin" @click="unSuspendClient()">Unsuspend</b-button>
                  </span>
                </b-col>
              </b-form-row>
            </form>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import {isAdmin, isSuperAdmin} from "@/helpers/api.helper";

export default {
  name: "PersonalInformation",
  props: {
  },
  computed: {
    isAdmin() {
      return isAdmin();
    },
    isSuperAdmin() {
      return isSuperAdmin();
    }
  },
  data() {
    return {
      isLoading: false,
      statusOptions: [],
      clientId: null,
      client: {}
    }
  },
  mounted() {
    this.clientId = this.$route.params.client_id;

    // console.log(this.clientId)
    if (this.clientId !== null) {
      this.getClient();

      this.isLoading = true;
      axios.get(process.env.VUE_APP_MOJA_API_URL + "/status", {
        "headers": {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.token
        }
      }).then(response => {
        this.statusOptions = response.data
      }).catch( error => {
        console.log(error.code)
        console.log(error.message)
      })
    }

  },
  methods: {
    getClient() {
      this.isLoading = true;
      // console.log(this.$route.params.client_id)
      // console.log(this.clientId)
      axios.get(process.env.VUE_APP_MOJA_API_URL + "/client/" + this.clientId, {
        "headers": {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.token
        }
      }).then(response => {
        // console.log(response)
        if (response.status === 200) {
          // console.log(response.data)
          this.client = {
            status_id: response.data.status.id,
            ...response.data
          }
        }

        this.isLoading = false;
      }).catch(error => {
        console.error(error);
        console.error(error);

        this.isLoading = false;
      });
    },
    updateClient() {
      this.isLoading = true;
      axios.put(process.env.VUE_APP_MOJA_API_URL + '/client/' + this.clientId, this.client, {
        "headers": {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.$store.state.token
        }
      }).then(response => {
        if (response.status === 200) {
          this.$bvModal.msgBoxOk('Client information updated successfully', {
            title: 'Confirmation',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: 'Okay',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
            .then((result) => {
              this.getClient()
              console.log(result)
            })
            .catch((err) => {
              // An error occurred
              console.error(err)
            });
        }

        this.isLoading = false;
      })
    },
    suspendClient() {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to suspend ${this.client.first_name}  ?` , {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        cancelTitle: 'Nope',
        cancelVariant: 'secondary',
        okVariant: 'primary',
        okTitle: 'Yes',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then((result) => {
          console.log(result);

          if (result === true) {
            axios.put(process.env.VUE_APP_MOJA_API_URL + "/client/" + this.clientId + "/suspend", {}, {
              "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.$store.state.token
              }
            }).then(response => {
              if (response.status === 200) {
                this.getClient();
              }
            })
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err)
        });
    },
    unSuspendClient() {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to unsuspend ${this.client.first_name}  ?` , {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        cancelTitle: 'Nope',
        cancelVariant: 'secondary',
        okVariant: 'primary',
        okTitle: 'Yes',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then((result) => {
          console.log(result);

          if (result === true) {
            axios.put(process.env.VUE_APP_MOJA_API_URL + "/client/" + this.clientId + "/unsuspend", {}, {
              "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.$store.state.token
              }
            }).then(response => {
              if (response.status === 200) {
                this.getClient();
              }
            })
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err)
        });
    }
  }
}
</script>

<style scoped>

</style>
