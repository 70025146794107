<template>
  <div class="manage-users">
    <PageHeader page-title="Manage System Users"></PageHeader>
    <b-card no-body>
      <template #header>
        <div class="card-header-content-between">
          <h4>Users</h4>
          <b-form-group>
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </template>

      <b-card-body>
        <b-button @click.prevent="openNewUserModal()" variant="primary" size="sm">New</b-button>

        <b-modal v-model="openNewUserModel"
                 size="xl"
                 title="New User"
                 no-close-on-esc
                 no-close-on-backdrop>
          <form autocomplete="off">
            <b-col>
              <b-form-group
                id="first-name-input"
                label="First Name"
                label-for="first_name"
                description=""
              >
                <b-form-input
                  id="first_name"
                  type="text"
                  placeholder=""
                  v-model.trim="$v.userForm.first_name.$model"
                  :class="{'is-invalid': $v.userForm.first_name.$error}"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                id="last-name-input"
                label="Last Name"
                label-for="last_name"
                description=""
              >
                <b-form-input
                  id="first_name"
                  type="text"
                  placeholder=""
                  v-model="$v.userForm.last_name.$model"
                  :class="{'is-invalid': $v.userForm.last_name.$error}"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                id="last-email-input"
                label="Email"
                label-for="email"
                description=""
              >
                <b-form-input
                  id="email"
                  type="text"
                  placeholder=""
                  v-model="$v.userForm.email.$model"
                  :class="{'is-invalid': $v.userForm.email.$error}"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
            <b-form-group label="User roles" label-for="roles-component-select">
              <b-form-tags
                id="roles-component-select"
                v-model.trim="$v.userForm.roles.$model"
                :class="{'is-invalid': $v.userForm.roles.$error}"
                size="lg"
                class="mb-2"
                add-on-change
                no-outer-focus
              >
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">

                  <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                        @remove="removeTag(tag)"
                        :title="tag"
                        :disabled="disabled"
                        variant="info"
                      >{{ tag }}</b-form-tag>
                    </li>
                  </ul>

                  <b-form-select
                    v-bind="inputAttrs"
                    v-on="inputHandlers"
                    :disabled="disabled || availableOptions.length === 0"
                    :options="availableOptions"
                  >
                    <template #first>
                      <!-- This is required to prevent bugs with Safari -->
                      <option disabled value="">Choose a role...</option>
                    </template>
                  </b-form-select>
                </template>
              </b-form-tags>
            </b-form-group>

            </b-col>

            <b-col>
              <b-form-checkbox v-model.trim="$v.userForm.update_password.$model" name="check-button" switch v-if="isEditing">
                Update password
              </b-form-checkbox>
              <b-form-group v-if="$v.userForm.update_password.$model"
                id="last-password-input"
                label="Password"
                label-for="password"
                description=""
              >

                <b-form-input
                  id="password"
                  type="password"
                  placeholder=""
                  :class="{'is-invalid': $v.userForm.password.$error}"
                  v-model.trim="$v.userForm.password.$model"
                ></b-form-input>

              </b-form-group>
            </b-col>

          </form>

          <template #modal-footer>
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="openNewUserModel=false"
            >
              Close
            </b-button>

            <b-button size="sm" variant="success" @click="createNewUser()" v-if="!isEditing" :disabled="$v.userForm.$invalid || isLoading">
              Save
            </b-button>
            <b-button size="sm" variant="success" @click="updateUser(userForm.id)" v-if="isEditing" :disabled="$v.userForm.$invalid || isLoading">
              Update
            </b-button>
          </template>
        </b-modal>

      </b-card-body>

      <b-table striped hover :fields="fields"
               :filter="filter" :items="users" :busy="isLoading"
               sort-by="id"
               sort-desc
               responsive="sm"
               small
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>

        <template #cell(roles)="row">
          <small>{{row.value}}</small>
        </template>

        <template #cell(created_at)="row">
          <StandardDateDisplay :time="row.value"></StandardDateDisplay>
        </template>

        <template #cell(updated_at)="row">
          <StandardDateDisplay :time="row.value"></StandardDateDisplay>
        </template>

        <template #cell(action)="row">
          <b-button @click.prevent="" variant="primary" size="sm" @click="editUser(row.item.id)">View</b-button>
        </template>

      </b-table>

    </b-card>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import MojaAPIService from "@/service/MojaAPIService";
import StandardDateDisplay from "@/components/StandardDateDisplay";
import {required, email} from "vuelidate/lib/validators";
import {getUserRoles} from "@/helpers/api.helper";

export default {
  name: "ManageUserPage",
  components: {StandardDateDisplay, PageHeader},
  computed: {
    availableOptions() {
      return this.roles.filter(opt => this.userForm.roles.indexOf(opt) === -1)
    }
  },
  data() {
    return {
      isLoading: true,
      isEditing: false,
      filter: null,
      roles: getUserRoles(),
      openNewUserModel: false,
      userForm: {
        id: null,
        first_name: '',
        last_name: '',
        email: '',
        roles: [],
        password: '',
        created_at: null,
        updated_at: null,
        update_password: null
      },
      fields: [
        { key: 'id', sortable: true },
        { label: 'Name', key: 'first_name', sortable: true },
        { label: 'Surname', key: 'last_name', sortable: true },
        { label: 'Username', key: 'email', sortable: true },
        { label: 'Roles', key: 'roles', sortable: true },
        { label: 'Created', key: 'created_at', sortable: true },
        { label: 'Updated', key: 'updated_at', sortable: true },
        { label: 'Action', key: 'action', sortable: false }
      ],
      users: []
    }
  },
  mounted() {
    this.getAllUsers()
  },
  methods: {
    openNewUserModal() {
      this.userForm = {
        id: null,
        first_name: '',
        last_name: '',
        email: '',
        roles: [],
        password: '',
        created_at: null,
        updated_at: null,
      }
      this.$v.userForm.update_password.$model = true
      this.isEditing=false

      this.$v.reset
      this.openNewUserModel=true
    },
    getAllUsers() {
      this.isLoading = true
      MojaAPIService.getAllUsers().then(response => {
        this.isLoading = true
        if (response.status === 200) {
          this.users = response.data
        }

        this.isLoading = false;
      });
    },
    createNewUser() {
      this.isLoading = true
      if (!this.$v.userForm.$invalid) {
        const data = {
          first_name: this.$v.userForm.first_name.$model,
          last_name: this.$v.userForm.last_name.$model,
          email: this.$v.userForm.email.$model,
          roles: this.$v.userForm.roles.$model,
          password: this.$v.userForm.password.$model
        };
        MojaAPIService.createNewUser(data).then( response => {
          if (response.status === 200) {
            this.getAllUsers()
          }

          this.openNewUserModel = false
          this.isLoading = false
        }).catch(({response}) => {
          console.log(response);
          if (response.status >= 400) {
            this.$bvToast.toast(response.data.message, {
              title: 'Oops and error has occurred',
              variant: 'danger',
              solid: true
            });

          }
        });
      }

      this.isLoading = true
    },
    editUser(userId) {
      this.isLoading = true
      this.isEditing = true
      this.userForm = {
        id: null,
        first_name: '',
        last_name: '',
        email: '',
        roles: [],
        password: '',
        created_at: null,
        updated_at: null,
      }

      MojaAPIService.getUserBtId(userId).then( response => {
        if (response.status === 200) {
          response.data.password = '*********';
          response.data.update_password = false;
          this.userForm = response.data;

          this.$v.reset;
        }

        this.isLoading = false
        this.openNewUserModel = true
      })
    },
    updateUser(userId) {
      this.isLoading = true;

      if (!userId) return;
      if (this.$v.userForm.$invalid) return;

      if (!this.$v.userForm.password.$dirty) {
        this.$v.userForm.update_password.$model = false;
        this.$v.userForm.password.$model = null;
      }

      const data = {
        first_name: this.$v.userForm.first_name.$model,
        last_name: this.$v.userForm.last_name.$model,
        email: this.$v.userForm.email.$model,
        roles: this.$v.userForm.roles.$model,
        password: this.$v.userForm.password.$model,
        update_password: this.$v.userForm.update_password.$model
      };

      MojaAPIService.updateUser(userId, data).then( response => {
        if (response.status === 200) {
          this.getAllUsers();
        }

        this.isLoading = false;
        this.openNewUserModel = false;
      });
    }
  },
  validations: {
    userForm: {
      id: {
      },
      first_name: {
        required
      },
      last_name: {
        required
      },
      email: {
        required,
        email
      },
      roles: {
        required
      },
      password: {
        required
      },
      update_password: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
