<template>
  <div class="status-badge">
    <b-badge v-if="status.id === 1" variant="success">{{status.name}}</b-badge>
    <b-badge v-if="status.id === 2" variant="info">{{status.name}}</b-badge>
    <b-badge v-if="status.id === 3" variant="danger">{{status.name}}</b-badge>
    <b-badge v-if="status.id === 4" variant="danger">{{status.name}}</b-badge>
    <b-badge v-if="status.id === 5" variant="warning">{{status.name}}</b-badge>
    <b-badge v-if="status.id === 6" variant="info">{{status.name}}</b-badge>
    <b-badge v-if="status.id === 8" variant="warning">{{status.name}}</b-badge>
    <b-badge v-if="status.id === 11" variant="info">{{status.name}}</b-badge>
    <b-badge v-if="status.id === 12" variant="secondary">{{status.name}}</b-badge>
    <b-badge v-if="status.id === 13" variant="secondary">{{status.name}}</b-badge>
    <b-badge v-if="status.id === 14" variant="danger">{{status.name}}</b-badge>
    <b-badge v-if="status.id === 15" variant="info">{{status.name}}</b-badge>
  </div>
</template>

<script>
export default {
  name: "StatusBadge",
  props: {
    status: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
