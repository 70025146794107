<template>
  <div class="single-client-product-page">

    <PageHeader page-title="Manage Client Product"></PageHeader>

    <div class="row">
      <div class="col-md-4">
        <ClientProductInformation></ClientProductInformation>
      </div>
      <div class="col-md-8">
        <ClientProductAttributes :client_product_id="clientProductId"/>

        <ClientProductTopUps></ClientProductTopUps>
      </div>
    </div>

    <div class="row">

      <div class="col-md-12">

      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ClientProductInformation from "@/components/ClientProduct/ClientProductInformation";
import ClientProductTopUps from "@/components/client/ClientProductTopUps";
import ClientProductAttributes from "@/components/client/ClientProductAttributes.vue";
import MojaAPIService from "@/service/MojaAPIService";

export default {
  name: "SingleClientProductPage",
  components: {ClientProductAttributes, ClientProductTopUps, ClientProductInformation, PageHeader},
  mounted() {
    this.clientProductId = this.$route.params.client_product_id

    MojaAPIService.getClientProductById(this.$route.params.client_product_id).then( response => {
      if (response.status === 200) {
        this.clientProduct = response.data;
        this.isLoading = false;
      }
    });
  },
  data() {
    return {
      clientProductId: null,
      clientProduct: {}
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
